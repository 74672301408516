import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';
import Select from '../../Inputs/Select';
import TextField from '../../Inputs/TextField';
import css from './BusinessCashbackSplitage.module.scss';

const SYSTEM_DEFAULT_OPTION = 'SYSTEM_DEFAULT_VALUE';
const CUSTOM_OPTION = 'CUSTOM_VALUE';

const systemDefaultEntry = {
  label: 'Use system default',
  value: SYSTEM_DEFAULT_OPTION,
};
const customEntry = { label: 'Custom', value: CUSTOM_OPTION };
const cashbackSplitOptions = [systemDefaultEntry, customEntry];

const validateChange = (
  cashbackSplitOption,
  hasBusinessOverride,
  userCashbackPercentage,
  userSharePercentage,
  onStateChange
) => {
  const newHasBusinessOverride = cashbackSplitOption.value === CUSTOM_OPTION;
  if (
    userCashbackPercentage !== userSharePercentage ||
    newHasBusinessOverride !== hasBusinessOverride
  ) {
    onStateChange({
      userCashbackPercentage: userSharePercentage,
      hasBusinessOverride: cashbackSplitOption.value === CUSTOM_OPTION,
    });
  }
};
const isPercentageAllowed = (values) => {
  const { value } = values;
  return value <= 100 && value >= 0;
};

const BusinessCashbackSplitage = ({
  userCashbackPercentage,
  systemWideCashbackUserSplitPercentage,
  hasBusinessOverride,
  onStateChange,
  cashbackSplitOptionError,
  errors,
}) => {
  const [cashbackSplitOption, setCashbackSplitOption] =
    useState(systemDefaultEntry);
  const [userSharePercentage, setUserSharePercentage] = useState('0');
  const [companySharePercentage, setCompanySharePercentage] = useState('100');
  const [systemUserSharePercentage, setSystemUserSharePercentage] =
    useState('0');
  const [systemCompanySharePercentage, setSystemCompanySharePercentage] =
    useState('100');
  useEffect(() => {
    if (hasBusinessOverride) {
      setCashbackSplitOption(customEntry);
    } else {
      setCashbackSplitOption(systemDefaultEntry);
    }
  }, [hasBusinessOverride]);
  useEffect(() => {
    setSystemUserSharePercentage(
      normalizeFloat(systemWideCashbackUserSplitPercentage)
    );
    setSystemCompanySharePercentage(
      normalizeFloat(100 - parseFloat(systemWideCashbackUserSplitPercentage))
    );
  }, [systemWideCashbackUserSplitPercentage]);
  useEffect(() => {
    if (userCashbackPercentage !== null) {
      setUserSharePercentage(
        userCashbackPercentage
          ? normalizeFloat(userCashbackPercentage)
          : userCashbackPercentage
      );
      if (userCashbackPercentage === '100' && companySharePercentage === '') {
        return;
      }
      setCompanySharePercentage(
        normalizeFloat(100 - parseFloat(userCashbackPercentage || '0'))
      );
    } else {
      setUserSharePercentage(
        normalizeFloat(systemWideCashbackUserSplitPercentage)
      );
      setCompanySharePercentage(
        normalizeFloat(100 - parseFloat(systemWideCashbackUserSplitPercentage))
      );
    }
  }, [
    companySharePercentage,
    systemWideCashbackUserSplitPercentage,
    userCashbackPercentage,
  ]);

  const onNumericsBlur = useCallback(() => {
    const realUserSharePercentage = normalizeFloat(userSharePercentage || '0');
    const realCompanySharePercentage = normalizeFloat(
      100 - parseFloat(realUserSharePercentage)
    );
    setUserSharePercentage(realUserSharePercentage);
    setCompanySharePercentage(realCompanySharePercentage);
  }, [userSharePercentage]);
  const userShareChangeHandler = useCallback(
    (values) => {
      const { value } = values;
      if (cashbackSplitOption !== systemDefaultEntry) {
        const newUserSharePercentage = value ? normalizeFloat(value) : value;
        setUserSharePercentage(newUserSharePercentage);
        validateChange(
          cashbackSplitOption,
          hasBusinessOverride,
          userCashbackPercentage,
          newUserSharePercentage,
          onStateChange
        );
        if (value === '100' && !companySharePercentage) {
          return;
        }
        setCompanySharePercentage(
          normalizeFloat(100 - parseFloat(value || '0'))
        );
      }
    },
    [
      cashbackSplitOption,
      companySharePercentage,
      hasBusinessOverride,
      onStateChange,
      userCashbackPercentage,
    ]
  );
  const companyShareChangeHandler = useCallback(
    (values) => {
      const { value } = values;
      if (cashbackSplitOption !== systemDefaultEntry) {
        const newUserSharePercentage =
          value === '100' && !userSharePercentage
            ? userSharePercentage
            : normalizeFloat(100 - parseFloat(value || '0'));
        setCompanySharePercentage(value ? normalizeFloat(value) : value);
        setUserSharePercentage(newUserSharePercentage);
        validateChange(
          cashbackSplitOption,
          hasBusinessOverride,
          userCashbackPercentage,
          newUserSharePercentage,
          onStateChange
        );
      }
    },
    [
      cashbackSplitOption,
      hasBusinessOverride,
      onStateChange,
      userCashbackPercentage,
      userSharePercentage,
    ]
  );
  const cashbackSplitChangeHandler = useCallback(
    (value) => {
      setCashbackSplitOption(value);
      validateChange(
        value,
        hasBusinessOverride,
        userCashbackPercentage,
        userSharePercentage,
        onStateChange
      );
    },
    [
      hasBusinessOverride,
      onStateChange,
      userCashbackPercentage,
      userSharePercentage,
    ]
  );
  return (
    <>
      <Select
        value={cashbackSplitOption}
        name="cashbackSplitOption"
        label="Cashback Split"
        placeholder="Cashback Split"
        options={cashbackSplitOptions}
        onChange={cashbackSplitChangeHandler}
        clearable={false}
        disabled={cashbackSplitOptionError}
        error={
          cashbackSplitOptionError &&
          'Error getting system wide cashback split percentage'
        }
      />
      <TextField
        name="userShare"
        value={
          cashbackSplitOption === systemDefaultEntry
            ? systemUserSharePercentage
            : userSharePercentage
        }
        label="User Share"
        onValueChange={userShareChangeHandler}
        onChange={() => {}}
        type="number"
        format={null}
        decimalScale={2}
        allowNegative={false}
        suffix="%"
        isAllowed={isPercentageAllowed}
        className={css.inSameLine}
        disabled={
          cashbackSplitOption === systemDefaultEntry || cashbackSplitOptionError
        }
        error={errors && errors.userCashbackPercentage}
        allowEmptyFormatting={true}
        onNumericBlur={onNumericsBlur}
      />

      <TextField
        name="companyShare"
        value={
          cashbackSplitOption === systemDefaultEntry
            ? systemCompanySharePercentage
            : companySharePercentage
        }
        label="Company Share"
        onValueChange={companyShareChangeHandler}
        onChange={() => {}}
        type="number"
        format={null}
        decimalScale={2}
        allowNegative={false}
        suffix="%"
        isAllowed={isPercentageAllowed}
        className={css.inSameLine}
        disabled={
          cashbackSplitOption === systemDefaultEntry || cashbackSplitOptionError
        }
        allowEmptyFormatting={true}
        onNumericBlur={onNumericsBlur}
      />
    </>
  );
};

BusinessCashbackSplitage.propTypes = {
  userCashbackPercentage: PropTypes.string,
  systemWideCashbackUserSplitPercentage: PropTypes.string,
  hasBusinessOverride: PropTypes.bool,
  onStateChange: PropTypes.func.isRequired,
  cashbackSplitOptionError: PropTypes.bool,
  errors: PropTypes.object,
};

BusinessCashbackSplitage.defaultProps = {
  userCashbackPercentage: null,
  systemWideCashbackUserSplitPercentage: null,
  hasBusinessOverride: false,
};

export { BusinessCashbackSplitage };
