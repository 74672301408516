import { useCallback, useEffect, useState } from 'react';
import List from './List';
import { isEmpty } from '../../utils';
import Pagination from '../Layout/Pagination';
import AsyncSelect from '../Inputs/AsyncSelect';
import Select from '../Inputs/Select';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from '../Inputs/DatePicker';
import { Button, FormControlLabel, Switch } from '@mui/material';
import instance from 'src/http';

const activityOptions = [
  { label: 'Sale', value: 'affiliate_sale' },
  { label: 'Lead', value: 'affiliate_lead' },
  { label: 'Click', value: 'affiliate_click' },
  { label: 'Bonus', value: 'affiliate_bonus' },
];

const columns = [
  { label: 'DateTime', name: 'activityDate', type: 'datetime' },
  { label: 'Username', name: 'user', type: 'username' },
  { label: 'Business Name', name: 'business' },
  { label: 'Program', name: 'program' },
  { label: 'Activity Type', name: 'activityType' },
  { label: 'Total Commission', name: 'affiliateCommissionAmount' },
  { label: 'User commission share', name: 'userCashback' },
  { label: 'UPP Commission Share', name: 'uppCommision' },
  {
    label: 'Commission DateTime',
    name: 'affiliateCommissionDate',
    type: 'datetime',
  },
  { label: 'Total Purchase Amount', name: 'spendAmount' },
];

export default function AffiliateTrackingDashboard({ history, match }) {
  const [businessOptions, setBusinessOptions] = useState([]);
  const [affiliateProgramOptions, setAffliateProgramOptions] = useState([]);

  useEffect(() => {
    fetchBusinesses();
    fetchAffiliatePrograms();
  }, []);

  const [totalResults, setTotalResults] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [userFilter, setUserFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortBy, setSortBy] = useState({ field: 'activityDate', asc: false });
  const [business, setBusiness] = useState([]);
  const [affiliateProgram, setAffiliateProgram] = useState([]);
  const [activityType, setActivityType] = useState([]);
  const [showUnhealthy, setShowUnhealthy] = useState(false);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const { params } = match;

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No data found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      );
  };

  const changePage = (page) => {
    fetchData(page);
    history.push(`/dashboard/transactions/${page}`);
  };

  const fetchAffiliatePrograms = async () => {
    try {
      const res = await instance.get('/affiliates');

      setAffliateProgramOptions(
        res.data.map((el) => ({
          label: el.name,
          value: el.id,
        }))
      );
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const fetchBusinesses = async () => {
    instance
      .get('/businesses/dropdown')
      .then((res) => {
        setBusinessOptions(res.data);
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };

  const fetchUsers = async (inputValue) => {
    const url = '/users';
    const options = {
      dropdown: true,
      search: inputValue,
    };

    try {
      const res = await instance.get(url, { params: options });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  const fetchData = useCallback(
    async (page = 1, csv = false) => {
      const url = '/reports/affiliateActivity';
      const options = {
        page: page,
        user: userFilter && userFilter.map((el) => el.value).toString(),
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
        csv: csv,
        business: business && business.map((el) => el.value).toString(),
        program:
          affiliateProgram &&
          affiliateProgram
            .map((el) => (el.value === null ? 'null' : el.label))
            .toString(),
        activity: activityType && activityType.map((el) => el.value).toString(),
        order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
      };
      if (showUnhealthy) {
        options.healthy = false;
      }
      if (page === 1) history.push(`/dashboard/transactions/1`);
      setErrors({});
      setLoading(true);
      try {
        const res = await instance.get(url, { params: options });

        if (csv) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `transactions-export-${moment().format('MM-DD-YYYY')}.csv`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          setTransactionData(res.data.rows);
          setTotalResults(res.data.count);
        }
        setLoading(false);
      } catch (error) {
        setErrors(error.response.data);
        setLoading(false);
        setTotalResults(0);
      }
    },
    [
      userFilter,
      startDate,
      endDate,
      showUnhealthy,
      sortBy.field,
      sortBy.asc,
      business,
      affiliateProgram,
      activityType,
      history,
    ]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <h1>Affiliate Tracking Dashboard</h1>

      <Controls>
        <AsyncSelect
          name="userId"
          label="User"
          loadOptions={fetchUsers}
          value={userFilter}
          isMulti
          onChange={(value, valueType) => setUserFilter(value)}
        />
        <Select
          value={business}
          name="Business"
          label="Business"
          options={businessOptions}
          onChange={(v) => setBusiness(v)}
          isMulti
          className="input-lg"
        />
        <Select
          value={affiliateProgram}
          name="affiliateProgram"
          label="Affiliate Program"
          placeholder="Affiliate Program"
          options={[{ value: null, label: 'None' }, ...affiliateProgramOptions]}
          className="input-lg"
          onChange={(v) => setAffiliateProgram(v)}
          isMulti
          error={errors.affiliateProgramId}
        />

        <DatePicker
          name="startDate"
          selected={startDate}
          label="From Date"
          onChange={(date) => setStartDate(date)}
          maxDate={endDate}
          isClearable={true}
          placeholder="Start Date"
        />
        <DatePicker
          name="endDate"
          selected={endDate}
          label="To Date"
          onChange={(date) => setEndDate(date)}
          minDate={startDate}
          isClearable={true}
          placeholder="End Date"
        />
        <Select
          value={activityType}
          name="activityType"
          label="Activity Type"
          placeholder="Activity Type"
          options={activityOptions}
          className="input-lg"
          onChange={(v) => setActivityType(v)}
          isMulti
          error={errors.activityType}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showUnhealthy}
              onChange={(event) => setShowUnhealthy(event.target.checked)}
            />
          }
          label="Show transactions with missing data"
          labelPlacement="start"
        />
      </Controls>
      <Button onClick={() => fetchData(1, true)}>Export</Button>
      <List
        columns={columns}
        setSort={setSort}
        sortBy={sortBy}
        data={transactionData}
        loading={loading}
        errors={errors}
        showInvalid
      />
      {renderPagination()}
    </div>
  );
}

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .input-cntr {
    margin: 10px;
    width: 15%;
  }
`;
