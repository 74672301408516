import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/authActions';
import Login from '../../auth/Login';
import Menu from './Menu';
import QuickActions from './QuickActions';
import Avatar from './Avatar';

const Navbar = ({ auth: { isAuthenticated, user } }) => {
  if (!isAuthenticated) {
    return (
      <div className="navbar">
        <Login />
      </div>
    );
  }

  return (
    <div className="navbar">
      <div>
        <div>
          <h2>Site manager</h2>
        </div>
        <QuickActions role={user.role} />
        <Menu role={user.role} />
      </div>
      <Avatar />
    </div>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
