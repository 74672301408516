import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Switch,
} from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import instance from 'src/http';
import { normalizeFloat } from '../../../utils';
import Spinner from '../../Inputs/Spinner';
import TextField from '../../Inputs/TextField';
import css from './AdjustUserCashbackBalance.module.scss';

const isAdjustCashbackValueAllowed = (values) => {
  const { value } = values;
  return value >= 0 && value <= 500;
};

const AdjustUserCashbackBalance = ({ open, closeHandler, userId }) => {
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [systemCurrency, setSystemCurrency] = useState('CAD');
  const [cashback, setCashback] = useState('0');
  const [adjustCashbackValue, setAdjustCashbackValue] = useState('0');
  const [isCredit, setIsCredit] = useState(true);
  const [adjustReason, setAdjustReason] = useState('');
  const onIsCreditChange = useCallback((event) => {
    setIsCredit(event.target.checked);
  }, []);
  const adjustCashbackChangeHandler = useCallback((values) => {
    const { value } = values;
    const realAdjustCashbackValue = value ? normalizeFloat(value) : value;
    setAdjustCashbackValue(realAdjustCashbackValue);
  }, []);
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    setError({});
    try {
      await instance.post(`/users/${userId}/cashback`, {
        adjustValue: adjustCashbackValue || '0',
        isCredit,
        adjustReason,
      });
      closeHandler();
    } catch (error) {
      const baseError = { message: 'Error adjusting cashback' };
      console.error(error);
      if (error.response && error.response.data) {
        setError({ ...error.response.data, ...baseError });
      } else {
        setError(baseError);
      }
    }
    setIsLoading(false);
  }, [adjustCashbackValue, adjustReason, closeHandler, isCredit, userId]);
  const handleCloseDialog = useCallback(() => {
    closeHandler();
  }, [closeHandler]);
  const handleAdjustReasonChange = useCallback((event) => {
    setAdjustReason(event.target.value);
  }, []);
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const res = await Promise.all([
          instance.get(`/users/${userId}/cashback`),
          instance.get('/system/system-currency'),
        ]);
        setCashback(res[0].data);
        setSystemCurrency(res[1].data);
      } catch (err) {
        setError({ message: "Cannot fetch user's cashback" });
        console.error(err);
      }
      setIsLoading(false);
    };
    setError({});
    fetchInitialData();
    return () => {};
  }, [userId]);
  const onNumericsBlur = useCallback(() => {
    const realAdjustCashbackValue = normalizeFloat(adjustCashbackValue || '0');
    setAdjustCashbackValue(realAdjustCashbackValue);
  }, [adjustCashbackValue]);
  return (
    <Dialog open={open} maxWidth="lg" disableBackdropClick>
      <DialogTitle>
        <div className={css.dialog_header}>
          <h3>Adjust Cashback Balance</h3>
          <i
            onClick={handleCloseDialog}
            className="fas fa-times closeDialog"
          ></i>
        </div>
      </DialogTitle>
      <div className={css.dialog_inner}>
        <div
          className={classnames('input-cntr', css.dialog_inner_item)}
        >{`Current cash back balance ${cashback} ${systemCurrency}`}</div>
        <TextField
          name="adjustValue"
          value={adjustCashbackValue}
          label={`Adjust Cashback Value (${isCredit ? 'Credit' : 'Debit'})`}
          onValueChange={adjustCashbackChangeHandler}
          onChange={() => {}}
          type="number"
          format={null}
          decimalScale={2}
          allowNegative={false}
          suffix={` ${systemCurrency}`}
          isAllowed={isAdjustCashbackValueAllowed}
          className={classnames(css.editControl, css.dialog_inner_item)}
          disabled={isLoading}
          error={error && error.adjustValue}
          allowEmptyFormatting={true}
          onNumericBlur={onNumericsBlur}
        />

        <div className={classnames('input-cntr', css.dialog_inner_item)}>
          <label className="label" htmlFor="isCredit">
            Is Credit
          </label>
          <Switch
            inputProps={{ id: 'isCredit' }}
            size="small"
            color="primary"
            checked={isCredit}
            onChange={onIsCreditChange}
            disabled={isLoading}
          ></Switch>
        </div>
        <TextField
          name="adjustReason"
          value={adjustReason}
          label={`${isCredit ? 'Credit' : 'Debit'} reason`}
          onChange={handleAdjustReasonChange}
          disabled={isLoading}
          error={error && error.adjustReason}
        />
        {error && error.message && (
          <div className="error_msg">{error.message}</div>
        )}
      </div>
      <DialogActions>
        {isLoading && <Spinner className={css.editSpinner} />}
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
        <Button onClick={handleCloseDialog} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdjustUserCashbackBalance.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  userId: PropTypes.number,
};

export { AdjustUserCashbackBalance };
