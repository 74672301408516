import React, { useState } from 'react';
import { Fab, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import instance from 'src/http';
import TextField from '../../Inputs/TextField';
import Button from '../../Inputs/Button';
import DatePicker from '../../Inputs/DatePicker';
import Spinner from '../../Inputs/Spinner';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: 'white',
    border: '1px dashed #000',
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: 'transparent',
    marginTop: 20,
    marginBottom: '18px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    display: 'flex',
    width: '100%',
  },
  label: {
    width: '100%',
    height: '100%',
  },
  fab: {
    border: 'none',
    backgroundColor: 'white',
    color: '#4A4A4A',
    boxShadow: 'none',
    textTransform: 'none',
    minHeight: 17,
    width: '90%',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'white',
    },
    margin: '20px',
  },
  input: {
    display: 'none',
  },
}));

function UploadCSV() {
  const classes = useStyles();
  const textDesc = 'Upload CSV';
  const [incorrectUsers, setIncorrectUsers] = useState([]);
  const [addedUsers, setAddedUsers] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeHandler = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append('csv', file);

    try {
      setLoading(true);
      const { data } = await instance.post('/users/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (!!data.Incorrect.length) {
        setIncorrectUsers(data.Incorrect);
      } else {
        setAddedUsers(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeField = (e, index) => {
    const value = e.target.value;
    const name = e.target.name;

    const updatedObject = { ...incorrectUsers[index], [name]: value };
    const updatedArray = [
      ...incorrectUsers.slice(0, index),
      updatedObject,
      ...incorrectUsers.slice(index + 1),
    ];
    setIncorrectUsers(updatedArray);
  };

  const handleChangeDate = (date, index) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    const updatedObject = { ...incorrectUsers[index], birthdate: newDate };
    const updatedArray = [
      ...incorrectUsers.slice(0, index),
      updatedObject,
      ...incorrectUsers.slice(index + 1),
    ];
    setIncorrectUsers(updatedArray);
  };

  const onSaveChanges = async () => {
    let formData = new FormData();
    const data = JSON.stringify(incorrectUsers);
    formData.append('users', data);

    try {
      await instance.post('/users/create', { users: incorrectUsers });
      setAddedUsers(true);
      setIncorrectUsers([]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className={classes.avatar}>
        <label htmlFor="contained-button-file" className={classes.label}>
          <input
            type="file"
            accept=".csv"
            className={classes.input}
            id="contained-button-file"
            name="file"
            onChange={changeHandler}
          />
          <Fab
            aria-label={textDesc}
            component="div"
            variant="extended"
            className={classes.fab}
          >
            {textDesc}
            {loading && <Spinner />}
          </Fab>
        </label>
      </div>
      {addedUsers && <Alert severity="success">User(s) added</Alert>}
      {!!incorrectUsers.length && (
        <div>
          <h2>Incorrect users data</h2>
          {incorrectUsers.map((el, idx) => {
            return (
              <div key={`${idx}`} style={{ width: '600px' }}>
                <h5
                  style={{
                    borderBottom: '1px solid #cacaca',
                    paddingBottom: '10px',
                  }}
                >
                  User {idx + 1}
                </h5>
                <TextField
                  name="firstName"
                  value={el.firstName}
                  label="First name"
                  onChange={(e) => handleChangeField(e, idx)}
                />
                <TextField
                  name="lastName"
                  value={el.lastName}
                  label="Last name"
                  onChange={(e) => handleChangeField(e, idx)}
                />
                <TextField
                  name="email"
                  value={el.email}
                  label="Email address"
                  onChange={(e) => handleChangeField(e, idx)}
                />
                <DatePicker
                  name="birthDate"
                  selected={new Date(el.birthdate)}
                  label="Date of birth"
                  onChange={(date) => handleChangeDate(date, idx)}
                />
              </div>
            );
          })}
          <Button text="Add users" onClick={onSaveChanges} />
        </div>
      )}
    </div>
  );
}

export default UploadCSV;
