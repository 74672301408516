import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Switch,
} from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';
import DatePicker from '../../Inputs/DatePicker';
import Spinner from '../../Inputs/Spinner';
import TextField from '../../Inputs/TextField';
import css from './BusinessCashbackScheduler.module.scss';

const isPercentageAllowed = (values) => {
  const { value } = values;
  return value >= 0 && value <= 100;
};

const BusinessCashbackSchedulerEdit = ({ scheduler, onFinish }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [singleDayEvent, setSingleDayEvent] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startDateEditable, setStartDateEditable] = useState();
  const [endDate, setEndDate] = useState();
  const [userSharePercentage, setUserSharePercentage] = useState('0');
  const [companySharePercentage, setCompanySharePercentage] = useState('100');
  const onNumericsBlur = useCallback(() => {
    const realUserSharePercentage = normalizeFloat(userSharePercentage || '0');
    const realCompanySharePercentage = normalizeFloat(
      100 - parseFloat(realUserSharePercentage)
    );
    setUserSharePercentage(realUserSharePercentage);
    setCompanySharePercentage(realCompanySharePercentage);
  }, [userSharePercentage]);
  const userShareChangeHandler = useCallback(
    (values) => {
      const { value } = values;
      const newUserSharePercentage = value ? normalizeFloat(value) : value;
      setUserSharePercentage(newUserSharePercentage);
      if (value === '100' && !companySharePercentage) {
        return;
      }
      setCompanySharePercentage(normalizeFloat(100 - parseFloat(value || '0')));
    },
    [companySharePercentage]
  );
  const companyShareChangeHandler = useCallback(
    (values) => {
      const { value } = values;
      setCompanySharePercentage(value ? normalizeFloat(value) : value);
      if (value === '100' && !userSharePercentage) {
        return;
      }
      const newUserSharePercentage = normalizeFloat(
        100 - parseFloat(value || '0')
      );
      setUserSharePercentage(newUserSharePercentage);
    },
    [userSharePercentage]
  );
  useEffect(() => {
    setIsLoading(false);
    setError({});
    if (scheduler) {
      const isNew = !scheduler.id;
      if (isNew) {
        setSingleDayEvent(false);
        setStartDate(moment(new Date()).add(1, 'days').toDate());
        setEndDate(moment(new Date()).add(2, 'days').toDate());
        setUserSharePercentage(normalizeFloat(0));
        setCompanySharePercentage(normalizeFloat(100));
      } else {
        if (!scheduler.endDate) {
          setSingleDayEvent(true);
          setEndDate(moment(scheduler.startDate).endOf('day').toDate());
        } else {
          setSingleDayEvent(false);
          setEndDate(moment(scheduler.endDate).endOf('day').toDate());
        }
        setStartDate(moment(scheduler.startDate).startOf('day').toDate());
        setUserSharePercentage(normalizeFloat(scheduler.userPercentage));
        setCompanySharePercentage(
          normalizeFloat(100 - parseFloat(scheduler.userPercentage))
        );
      }
    }
    return () => {};
  }, [scheduler]);
  useEffect(() => {
    if (!startDate) {
      setStartDateEditable(false);
    } else {
      const tomorrow = moment(new Date()).add(1, 'day').startOf('day');
      if (moment(startDate).isBefore(tomorrow)) {
        setStartDateEditable(false);
      } else {
        setStartDateEditable(true);
      }
    }
    return () => {};
  }, [startDate]);
  const handleCloseDialog = useCallback(() => {
    onFinish();
    setError({});
  }, [onFinish]);
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const resultingScheduler = { ...scheduler };
    resultingScheduler.startDate = moment(startDate).format('YYYY-MM-DD');
    resultingScheduler.endDate = singleDayEvent
      ? null
      : moment(endDate).format('YYYY-MM-DD');
    resultingScheduler.userPercentage = userSharePercentage || '0';
    setUserSharePercentage(resultingScheduler.userPercentage);
    setCompanySharePercentage(
      normalizeFloat(100 - parseFloat(resultingScheduler.userPercentage))
    );
    const errors = await onFinish(resultingScheduler);
    setError(errors || {});
    setIsLoading(false);
  }, [
    endDate,
    onFinish,
    scheduler,
    singleDayEvent,
    startDate,
    userSharePercentage,
  ]);
  const onSingleDayEventChange = useCallback((event) => {
    setSingleDayEvent(event.target.checked);
  }, []);
  const onStartDateChange = useCallback((date) => {
    setStartDate(date);
  }, []);
  const onEndDateChange = useCallback((date) => {
    setEndDate(date);
  }, []);
  return (
    <Dialog open={scheduler} maxWidth="lg" disableBackdropClick>
      <DialogTitle>
        <div className={css.dialog_header}>
          <h3>{scheduler?.id ? 'Edit Split' : 'New Split'}</h3>
          <i onClick={handleCloseDialog} className="fas fa-times closeDialog" />
        </div>
      </DialogTitle>
      <div className={css.dialog_inner}>
        <DatePicker
          name="startDate"
          selected={startDate}
          label="Start Date"
          onChange={onStartDateChange}
          placeholder="Start Date"
          className={css.dialog_inner_item}
          disabled={isLoading || !startDateEditable}
          error={error?.startDate}
          minDate={moment(new Date()).add(1, 'days').toDate()}
        />
        <DatePicker
          name="endDate"
          selected={endDate}
          label="End Date"
          onChange={onEndDateChange}
          placeholder="End Date"
          disabled={singleDayEvent || isLoading}
          className={css.dialog_inner_item}
          error={error?.endDate}
          minDate={moment(new Date()).add(1, 'days').toDate()}
        />
        <div className={classnames('input-cntr', css.dialog_inner_item)}>
          <label className="label" htmlFor="singleDayEvent">
            Single Day Event
          </label>
          <Switch
            inputProps={{ id: 'singleDayEvent' }}
            size="small"
            color="primary"
            checked={singleDayEvent}
            onChange={onSingleDayEventChange}
            disabled={isLoading}
          />
        </div>
        <TextField
          name="userShare"
          value={userSharePercentage}
          label="User Share"
          onValueChange={userShareChangeHandler}
          onChange={() => {}}
          type="number"
          format={null}
          decimalScale={2}
          allowNegative={false}
          suffix="%"
          isAllowed={isPercentageAllowed}
          className={classnames(css.editControl, css.dialog_inner_item)}
          disabled={isLoading}
          error={error?.userPercentage}
          allowEmptyFormatting={true}
          onNumericBlur={onNumericsBlur}
        />

        <TextField
          name="companyShare"
          value={companySharePercentage}
          label="Company Share"
          onValueChange={companyShareChangeHandler}
          onChange={() => {}}
          type="number"
          format={null}
          decimalScale={2}
          allowNegative={false}
          suffix="%"
          isAllowed={isPercentageAllowed}
          className={classnames(css.editControl, css.dialog_inner_item)}
          disabled={isLoading}
          allowEmptyFormatting={true}
          onNumericBlur={onNumericsBlur}
        />
        {error?.message && <div className="error_msg">{error.message}</div>}
        {error?.businessId && (
          <div className="error_msg">{error.businessId}</div>
        )}
      </div>
      <DialogActions>
        {isLoading && <Spinner className={css.editSpinner} />}
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
        <Button onClick={handleCloseDialog} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BusinessCashbackSchedulerEdit.propTypes = {
  scheduler: PropTypes.object,
  onFinish: PropTypes.func,
};

export { BusinessCashbackSchedulerEdit };
