import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { loadUser } from './redux/actions/authActions';
import './App.scss';
import PrivateRoute from './components/auth/PrivateRoute';
import setAuthToken from './components/auth/setAuthToken';
import Dashboard from './components/Dashboard/Dashboard';
import Navbar from './components/Layout/Navbar/Navbar';
import NotFound from './components/Layout/NotFound';
import Home from './components/Layout/Home';
import PasswordReset from './components/auth/PasswordReset';
import InputTest from './components/Inputs/InputTest';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Posts
import Posts from './components/Dashboard/Posts/Posts';
import Post from './components/Dashboard/Posts/Post';

// Pages
import Page from './components/Dashboard/Pages/Page';
import Pages from './components/Dashboard/Pages/Pages';

// Users
import Users from './components/Dashboard/Users/Users';
import ProfileSettings from './components/Dashboard/Settings/ProfileSettings';
import EditUser from './components/Dashboard/Users/EditUser';

// System
import SystemSettings from './components/Dashboard/System/SystemSettings';

// Discounts
import Discount from './components/Dashboard/Discounts/Discount';
import Discounts from './components/Dashboard/Discounts/Discounts';

// Businesses
import Business from './components/Dashboard/Businesses/Business';
import Businesses from './components/Dashboard/Businesses/Businesses';

// Categories
import Categories from './components/Dashboard/Categories/Categories';

//Tags
import Tags from './components/Dashboard/Tags/Tags';

import Dropdowns from './components/Dashboard/DropdownManagement/Dropdowns';
import Breadcrumbs from './components/Layout/Breadcrumbs';
import Carousel from './components/Dashboard/Carousel/Carousel';

// POLLS
import PollDashboard from './components/Dashboard/Polls/PollDashboard';
import CreatePoll from './components/Dashboard/Polls/CreatePoll';
import Poll from './components/Dashboard/Polls/Poll';

// Top Visitors Report
import TopVisitors from './components/Dashboard/TopVisitorsReport/TopVisitors';

// Media Library
import MediaLibrary from './components/Dashboard/MediaLibrary/MediaLibrary';
import Auditlog from './components/auditlog/Auditlog';
import AffiliateTrackingDashboard from './components/affiliateTracking/AffiliateTrackingDashboard';
import AffiliateSummaryDashboard from './components/affiliateTracking/AffiliateSummaryDashboard';
import UserActivitySummary from './components/affiliateTracking/UserActivitySummary';
import LocalBusinessDashboard from './components/Dashboard/LocalBusiness/LocalBusinessDashboard';
import { setUTCOffsetHeader } from './utils';
import { Snackbar } from '@mui/material';
import SnackbarContext from './context/SnackbarContext';

setAuthToken();
setUTCOffsetHeader();

const App = () => {
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarMessage('');
  };

  const snackbar = {
    snackbarMessage,
    setSnackbarMessage,
  };

  return (
    <>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
      <SnackbarContext.Provider value={snackbar}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Provider store={store}>
            <Router>
              <div className="App">
                <Navbar />
                <div className="dashboard-margin"></div>
                <div className="dashboard">
                  <Breadcrumbs />
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/inputs" component={InputTest} />

                    <PrivateRoute
                      exact
                      path="/dashboard/"
                      component={Dashboard}
                      allowedRoles={[
                        'CONTENT EDITOR',
                        'USER ADMIN',
                        'WEBSITE ADMIN',
                      ]}
                    />

                    {/* PAGES */}
                    <PrivateRoute
                      exact
                      path="/dashboard/pages"
                      component={Pages}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/pages/:mode(edit|add)/:id?"
                      component={Page}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                      snackbar={snackbar}
                    />

                    {/* POSTS */}
                    <PrivateRoute
                      exact
                      path="/dashboard/posts/:mode(edit|add)/:id?"
                      component={Post}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                      snackbar={snackbar}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/posts"
                      component={Posts}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    {/* CATEGORIES */}
                    <PrivateRoute
                      exact
                      path="/dashboard/categories/:page?"
                      component={Categories}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    {/* TAGS */}
                    <PrivateRoute
                      exact
                      path="/dashboard/tags/:lang(en|fr)/:page?"
                      component={Tags}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    {/* DISCOUNTS */}
                    <PrivateRoute
                      exact
                      path="/dashboard/discounts"
                      component={Discounts}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/discounts/:id/:mode?/"
                      component={Discount}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    <PrivateRoute
                      exact
                      path="/dashboard/businesses/:bizId?/discounts/:id/:mode?/"
                      component={Discount}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />
                    {/* <PrivateRoute
                exact
                path="/dashboard/discounts/edit/:id"
                component={EditDiscount}
              /> */}

                    {/* BUSINESSES */}
                    <PrivateRoute
                      exact
                      path="/dashboard/businesses"
                      component={Businesses}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/businesses/:id/:mode?/:page?"
                      component={Business}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    {/* POLLS */}
                    <PrivateRoute
                      exact
                      path="/dashboard/polls/"
                      component={PollDashboard}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/polls/createpoll"
                      component={CreatePoll}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/polls/poll/:id"
                      component={Poll}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    {/* REPORTS */}
                    <PrivateRoute
                      exact
                      path="/dashboard/report"
                      component={TopVisitors}
                      allowedRoles={['WEBSITE ADMIN']}
                    />

                    {/* USERS */}
                    <PrivateRoute
                      exact
                      path="/dashboard/users/add"
                      component={EditUser}
                      allowedRoles={['USER ADMIN', 'WEBSITE ADMIN']}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/users/edit/:id"
                      component={EditUser}
                      allowedRoles={['USER ADMIN', 'WEBSITE ADMIN']}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/users/:page?"
                      component={Users}
                      allowedRoles={['USER ADMIN', 'WEBSITE ADMIN']}
                    />

                    {/* Dropdowns */}
                    <PrivateRoute
                      exact
                      path="/dashboard/dropdowns/:tab?/:page?"
                      component={Dropdowns}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    <PrivateRoute
                      exact
                      path="/dashboard/carousel"
                      component={Carousel}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    <PrivateRoute
                      exact
                      path="/dashboard/media-library"
                      component={MediaLibrary}
                      allowedRoles={['CONTENT EDITOR', 'WEBSITE ADMIN']}
                    />

                    {/* Audit log */}

                    <PrivateRoute
                      exact
                      path="/dashboard/audit/:page?"
                      component={Auditlog}
                    />

                    {/* Affiliate Tracking */}

                    <PrivateRoute
                      exact
                      path="/dashboard/transactions/:page?"
                      component={AffiliateTrackingDashboard}
                      allowedRoles={['USER ADMIN', 'WEBSITE ADMIN']}
                    />

                    {/* Business Summary Dashboard */}

                    <PrivateRoute
                      exact
                      path="/dashboard/affiliate-business-summary/:page?"
                      component={AffiliateSummaryDashboard}
                      allowedRoles={['USER ADMIN', 'WEBSITE ADMIN']}
                    />

                    {/* User Activity Summary */}

                    <PrivateRoute
                      exact
                      path="/dashboard/user-activity-summary/:page?"
                      component={UserActivitySummary}
                      allowedRoles={['USER ADMIN', 'WEBSITE ADMIN']}
                    />

                    {/* Local Business */}
                    <PrivateRoute
                      exact
                      path="/dashboard/local-business-subscriptions/:page?"
                      component={LocalBusinessDashboard}
                    />

                    {/* PROFILE */}
                    <PrivateRoute
                      exact
                      path="/dashboard/profile"
                      component={ProfileSettings}
                      snackbar={snackbar}
                      allowedRoles={[
                        'CONTENT EDITOR',
                        'WEBSITE ADMIN',
                        'USER ADMIN',
                      ]}
                    />

                    {/* SYSTEM SETTINGS */}
                    <PrivateRoute
                      exact
                      path="/dashboard/system/:page?"
                      component={SystemSettings}
                      allowedRoles={['SUPER']}
                    />

                    <Route
                      exact
                      path="/password-reset"
                      component={PasswordReset}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </Router>
          </Provider>
        </LocalizationProvider>
      </SnackbarContext.Provider>
    </>
  );
};

export default App;
