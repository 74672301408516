import instance from 'src/http';
import { GET_ERRORS, GET_MEDIA } from '../types';

export const getMediaLibrary = (params) => async (dispatch) => {
  try {
    const res = await instance.get('/media', {
      params: { ...params, dropdown: true },
    });
    dispatch({
      type: GET_MEDIA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
