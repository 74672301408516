import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import React from 'react';

const StyledInput = styled(TextField)`
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 20px 0;
`;

export default function SEOFields({ value, errors, handleChange }) {
  return (
    <div>
      <StyledInput
        value={value.metaDescription}
        name="metaDescription"
        label="Meta Description"
        placeholder="Meta description"
        className="input-lg"
        onChange={handleChange}
        error={errors.metaDescription}
      />
      <StyledInput
        value={value.twitterSummary}
        name="twitterSummary"
        label="Twitter Summary"
        placeholder="Twitter Summary"
        className="input-lg"
        onChange={handleChange}
        error={errors.twitterSummary}
      />
      <StyledInput
        value={value.openGraphTitle}
        name="openGraphTitle"
        label="Open Graph Title"
        placeholder="Open Graph Title"
        className="input-lg"
        onChange={handleChange}
        error={errors.openGraphTitle}
      />
      <StyledInput
        value={value.openGraphType}
        name="openGraphType"
        label="Open Graph Type"
        placeholder="Open Graph Type"
        className="input-lg"
        onChange={handleChange}
        error={errors.openGraphType}
      />
      <StyledInput
        value={value.openGraphUrl}
        name="openGraphUrl"
        label="Open Graph Url"
        placeholder="Open Graph Url"
        className="input-lg"
        onChange={handleChange}
        error={errors.openGraphUrl}
      />
      <StyledInput
        value={value.openGraphDescription}
        name="openGraphDescription"
        label="Open Graph Description"
        placeholder="Open Graph Description"
        className="input-lg"
        onChange={handleChange}
        error={errors.openGraphDescription}
      />
    </div>
  );
}
