/* eslint-disable react/no-did-update-set-state */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '../../Inputs/TextField';
import style from './Tag.module.scss';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import { isEmpty } from 'lodash';
import instance from 'src/http';

const Tag = ({ lang, tagData, id, fetchTags }) => {
  const [tag, setTag] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [error, setError] = useState({});
  const tagLang = lang === 'en' ? 'tags' : 'tagsfr';

  useEffect(() => {
    tagData && setTag(tagData);
    if (!isEmpty(tagData)) setCreateMode(false);
  }, [tagData]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setError({});
    if (!createMode) setTag(tagData);
  };

  let button = (
    <Button className={style.mb} onClick={() => setDialogOpen(true)}>
      New Tag
    </Button>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = createMode ? `/${tagLang}` : `/${tagLang}/${tag.id}`;
    const method = createMode ? 'post' : 'put';
    try {
      const res = await instance[method](url, tag);
      setDialogOpen(false);
      setTag({});
      fetchTags();
      setError({});
    } catch (err) {
      setError(err.response.data);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const url = `/${tagLang}/${tag.id}`;

    try {
      const res = await instance.delete(url);
      setConfirmDialogOpen(false);
      setDialogOpen(false);
      fetchTags();
    } catch (err) {
      setError(err.response.data);
    }
  };

  let submitBtnText = 'Create';
  if (!createMode) {
    button = (
      <i
        title="edit"
        className="far fa-edit"
        onClick={() => setDialogOpen(true)}
      ></i>
    );
    submitBtnText = 'Save';
  }

  let dialogMode = createMode ? 'New' : 'Edit';
  let entityType = 'Tag';
  return (
    <div>
      {button}

      <Dialog
        open={dialogOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">
          <div className={style.dialog_header}>
            <h3>{`${dialogMode} ${entityType}`}</h3>
            <i
              onClick={handleCloseDialog}
              className="fas fa-times closeDialog"
            ></i>
          </div>
        </DialogTitle>
        <div className={style.dialog_inner}>
          <TextField
            name="text"
            label={lang == 'en' ? 'Tag text (English)' : 'Tag text (French)'}
            placeholder={
              lang == 'en' ? 'Tag text (English)' : 'Tag text (French)'
            }
            value={tag.text}
            onChange={(e) => setTag({ ...tag, text: e.target.value })}
            autofocus
            error={error.text ? error.text : null}
          />
        </div>
        <DialogActions>
          <Button
            disabled={createMode}
            onClick={() => setConfirmDialogOpen(true)}
            color="secondary"
          >
            Delete
          </Button>
          <Button onClick={(e) => handleSubmit(e)} color="primary">
            {createMode ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${tag.text}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete '${tag.text}'?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            cancel
          </Button>
          <Button
            disabled={createMode}
            onClick={(e) => handleDelete(e)}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(Tag);
