import instance from 'src/http';
import { GET_ERRORS, GET_BUSINESS } from '../types';

export const getBusinesses = () => async (dispatch) => {
  try {
    const { data } = await instance.get('/public/businesses', {
      params: { dropdown: true },
    });

    dispatch({
      type: GET_BUSINESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
