import React, { useState } from 'react';
import css from './TopVisitors.module.scss';

import Pagination from '../../Layout/Pagination';
import { Button } from '@mui/material';

import Select from '../../Inputs/Select';
import DatePicker from '../../Inputs/DatePicker';
import moment from 'moment';
import Table from './Table';
import { isEmpty } from '../../../utils';
import instance from 'src/http';

export default function TopVisitors() {
  const [errors, setErrors] = useState({});
  const [CSVErrors, setCSVErrors] = useState({});
  const [loading, setloading] = useState(false);
  const [sterile, setSterile] = useState(false);
  const [page, setpage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [limit, setlimit] = useState({ label: 10, value: 10 });
  const [orderType, setOrderType] = useState({
    label: 'Descending',
    value: 'DESC',
  });
  const [orderField, setOrderField] = useState({
    label: 'Visit Count',
    value: 'visitCount',
  });
  const getData = async (page = 1, csv = false) => {
    setloading(true);
    if (page === 1 && csv === false) setpage(1);
    if (!csv) setSterile(true);

    const path = '/reports/visitors';
    const params = {
      params: {
        page: page,
        limit: limit.value,
        orderField: orderField.value,
        orderType: orderType.value,
        startDate: startDate && moment(startDate).startOf('date').toDate(),
        endDate: endDate && moment(endDate).endOf('date').toDate(),
        csv: csv,
      },
    };

    try {
      const res = await instance.get(path, params);
      setloading(false);
      if (csv) {
        setCSVErrors({});
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `submissions-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        setErrors({});
        setResults(res.data.results);
        setTotalResults(res.data.totalResults);
      }
    } catch (err) {
      setloading(false);
      if (csv) {
        setCSVErrors(err.response.data);
        setTotalResults(null);
      } else {
        setErrors(err.response);
        setTotalResults(null);
      }
    }
  };

  const changePage = (page) => {
    getData(page);
    setpage(page++);
  };
  const handleChangeLimit = (val) => {
    setlimit(val);
    setSterile(false);
  };
  const handleChangeOrderField = (val) => {
    setOrderField(val);
    setSterile(false);
  };
  const handleChangeOrderType = (val) => {
    setOrderType(val);
    setSterile(false);
  };

  const handleStartDate = (startDate) => {
    setStartDate(startDate);
    setSterile(false);
  };
  const handleEndDate = (endDate) => {
    setEndDate(endDate);
    setSterile(false);
  };

  const isExportDisabled = () => {
    return !Array.isArray(results) || results?.length === 0;
  };

  const sortOptions = [
    { label: 'First Name', value: 'firstName' },
    { label: 'Last Name', value: 'lastName' },
    { label: 'Email', value: 'email' },
    { label: 'Last Visit', value: 'lastVisit' },
    { label: 'Visit Count', value: 'visitCount' },
  ];

  return (
    <div className={css.container}>
      <div className={css.header_container}>
        <div>
          <h1>Top visitors report</h1>
          {isEmpty(CSVErrors) ? null : (
            <span className="error_msg">
              Export failed: {CSVErrors.message}
            </span>
          )}
        </div>
      </div>

      <div className={css.list_controls}>
        <DatePicker
          name="startDate"
          selected={startDate}
          label="Start date"
          onChange={handleStartDate}
          error={errors.startDate}
          maxDate={moment(endDate).toDate()}
          isClearable={true}
          placeholder="Start Date"
        />
        <DatePicker
          name="endDate"
          selected={endDate}
          label="End date"
          onChange={handleEndDate}
          error={errors.endDate}
          minDate={moment(startDate).toDate()}
          isClearable={true}
          placeholder="End Date"
        />
        <Select
          label="Sort by:"
          value={orderField}
          onChange={handleChangeOrderField}
          options={sortOptions}
          clearable={false}
          width={150}
        />
        <Select
          label="Sort:"
          value={orderType}
          onChange={handleChangeOrderType}
          options={[
            { label: 'Ascending', value: 'ASC' },
            { label: 'Descending', value: 'DESC' },
          ]}
          clearable={false}
          width={150}
        />
        <Select
          label="Show:"
          value={limit}
          onChange={handleChangeLimit}
          options={[
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 30, value: 30 },
          ]}
          clearable={false}
          width={50}
        />
        <div style={{ marginTop: '25px' }}>
          <Button
            isLoading={loading}
            onClick={() => getData(1)}
            disabled={sterile}
            variant="outlined"
            color="primary"
          >
            Generate
          </Button>
        </div>
      </div>
      <Button onClick={() => getData(1, true)} disabled={isExportDisabled()}>
        Export
      </Button>
      {isEmpty(errors) ? (
        <Table data={results} errors={errors} />
      ) : (
        <p>{errors.data.message}</p>
      )}

      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={page}
          onPageChange={changePage}
          pageLimit={limit.value}
        />
      ) : null}
    </div>
  );
}
