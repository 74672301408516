import React, { useEffect, useState } from 'react';
import List from './List';
import { isEmpty } from '../../utils';
import Pagination from '../Layout/Pagination';
import AsyncSelect from '../Inputs/AsyncSelect';
import Select from '../Inputs/Select';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from '../Inputs/DatePicker';
import { Button } from '@mui/material';
import TextField from '../Inputs/TextField';
import instance from 'src/http';

export default function UserActivitySummary({ history, match }) {
  const [businessOptions, setBusinessOptions] = useState([]);
  const [unionOptions, setUnionOptions] = useState([]);

  useEffect(() => {
    fetchBusinesses();
    fetchUnions();
  }, []);

  const [totalResults, setTotalResults] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [page, setPage] = useState(1);
  const [userFilter, setUserFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortBy, setSortBy] = useState({ field: 'username', asc: true });
  const [business, setBusiness] = useState([]);
  const [union, setUnion] = useState([]);
  const [local, setLocal] = useState('');
  const [activityType, setActivityType] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const { params } = match;

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No data found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      );
  };

  const changePage = (page) => {
    fetchData(page);
    setPage(page);
    history.push(`/dashboard/user-activity-summary/${page}`);
  };

  const fetchBusinesses = async () => {
    instance
      .get('/businesses/dropdown')
      .then((res) => {
        setBusinessOptions(res.data);
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };
  const fetchUnions = async () => {
    instance
      .get('/unions', { params: { dropdown: true } })
      .then((res) => {
        setUnionOptions(
          res.data.map((u) => {
            return { label: u.name, value: u.id };
          })
        );
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };

  const fetchUsers = async (inputValue) => {
    const url = '/users';
    const options = {
      dropdown: true,
      search: inputValue,
    };

    try {
      const res = await instance.get(url, { params: options });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  useEffect(() => {
    fetchData();
    setPage(1);
  }, [
    userFilter,
    startDate,
    endDate,
    JSON.stringify(sortBy),
    JSON.stringify(business),
    JSON.stringify(union),
    local,
    JSON.stringify(activityType),
  ]);

  const fetchData = async (page = 1, csv = false) => {
    const url = '/reports/userActivitySummary';
    const options = {
      page: page,
      csv: csv,
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString(),
      business: business && business.map((el) => el.value).toString(),
      union: union && union.map((el) => el.value).toString(),
      local: local,
      activity: activityType && activityType.map((el) => el.value).toString(),
      user: userFilter && userFilter.map((el) => el.value).toString(),
      order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
    };
    if (page === 1) history.push(`/dashboard/user-activity-summary/1`);
    setErrors({});
    setLoading(true);
    try {
      const res = await instance.get(url, { params: options });

      if (csv) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `user-activity-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        setTransactionData(res.data.rows);
        setTotalResults(res.data.count);
      }
      setLoading(false);
    } catch (error) {
      setErrors(error.response.data);
      setLoading(false);
      setTotalResults(0);
    }
  };

  const activityOptions = [
    { label: 'Sale', value: 'affiliate_sale' },
    { label: 'Lead', value: 'affiliate_lead' },
    { label: 'Click', value: 'affiliate_click' },
    { label: 'Bonus', value: 'affiliate_bonus' },
  ];

  const columns = [
    { label: 'User name', name: 'username' },
    { label: 'Union', name: 'unionName' },
    { label: 'Local', name: 'local' },
    { label: 'Total Spend', name: 'totalSpend' },
    { label: 'Total Commission', name: 'totalComission' },
    { label: 'User Commission', name: 'userCommision' },
    { label: 'UPP Commission', name: 'uppCommision' },
    { label: 'Number of leads', name: 'leads' },
    { label: 'Number of purchases', name: 'sales' },
    { label: 'Total user referrals', name: 'referralCount' },
    { label: 'Total business referrals', name: 'businessReferralCount' },
    { label: 'Registration Date', name: 'registrationDate', type: 'date' },
    { label: 'Number of UPP visits', name: 'uppVisits' },
    { label: 'Number of UPP page Hits/Clicks', name: 'uppClicksHits' },
  ];

  return (
    <div>
      <h1>User Summary Dashboard</h1>

      <Controls>
        <AsyncSelect
          name="userId"
          label="User"
          loadOptions={fetchUsers}
          value={userFilter}
          isMulti
          onChange={(value, valueType) => setUserFilter(value)}
        />
        <DatePicker
          name="startDate"
          selected={startDate}
          label="From Date"
          onChange={(date) => setStartDate(date)}
          maxDate={endDate}
          isClearable={true}
          placeholder="Start Date"
        />
        <DatePicker
          name="endDate"
          selected={endDate}
          label="To Date"
          onChange={(date) => setEndDate(date)}
          minDate={startDate}
          isClearable={true}
          placeholder="End Date"
        />
        <Select
          value={business}
          name="Business"
          label="Business"
          options={businessOptions}
          onChange={(v) => setBusiness(v)}
          isMulti
          className="input-lg"
        />
        <Select
          value={union}
          name="Union"
          label="Union"
          options={unionOptions}
          onChange={(v) => setUnion(v)}
          isMulti
          className="input-lg"
        />
        <TextField
          name="Local"
          label="Local"
          value={local}
          placeholder="Local"
          className="input-lg"
          onChange={(v) => setLocal(v.target.value)}
          maxLength={100}
        />
        <Select
          value={activityType}
          name="activityType"
          label="Activity Type"
          placeholder="Activity Type"
          options={activityOptions}
          className="input-lg"
          onChange={(v) => setActivityType(v)}
          isMulti
          error={errors.activityType}
        />
      </Controls>
      <Button onClick={() => fetchData(1, true)}>Export</Button>
      <List
        columns={columns}
        setSort={setSort}
        sortBy={sortBy}
        data={transactionData}
        loading={loading}
        errors={errors}
      />
      {renderPagination()}
    </div>
  );
}

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;

  .input-cntr {
    margin: 10px;
    width: 15%;
  }
`;
