import { alignmentOptions } from 'src/utils';
import CustomMUISelect from '../components/CustomMUISelect';
import Wysiwyg from '../components/Wysiwyg';

const ParagraphModule = ({
  alignment,
  showError,
  text,
  text_fr,
  handleChange,
  handleTextChange,
  handleTextFRChange,
}) => {
  return (
    <div>
      <CustomMUISelect
        name="alignment"
        label="Alignment"
        options={alignmentOptions}
        value={alignment}
        onChange={handleChange}
      />
      <Wysiwyg
        autoFocus
        charCounterMax={5000}
        name="content"
        label="Content"
        val={text}
        onChange={handleTextChange}
        error={showError && !text ? 'This field is required' : null}
      />
      <Wysiwyg
        charCounterMax={5000}
        name="content"
        label="Content (French)"
        val={text_fr}
        onChange={handleTextFRChange}
      />
    </div>
  );
};

export default ParagraphModule;
