import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select/async';
import classnames from 'classnames';

const Select = ({
  name,
  label,
  value,
  required,
  disabled,
  options,
  onChange,
  helperText,
  placeholder,
  clearable,
  className,
  width,
  loadOptions,
  cacheOptions,
  onInputChange,
  isMulti,
  error,
}) => {
  const hasError = error
    ? 'solid 2px #ef4e4e'
    : 'solid 2px rgba(188, 204, 220, 0.8)';
  const customStyle = {
    container: (styles, state) => ({
      ...styles,
      opacity: state.isDisabled ? '0.3' : '1',
      backgroundColor: state.isDisabled ? 'rgb(235, 235, 228)' : 'none',
    }),
    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? 'solid 2px #2186eb' : hasError,
      borderRadius: '6px',
      lineHeight: 'normal',
      boxShadow: 'none',
      background: 'none',
      backgroundColor: 'none',
      '&:hover': {
        border: state.isFocused
          ? 'solid 2px #2186eb'
          : 'solid 2px rgba(131, 142, 154, 0.6)',
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: '15px',
      color: '#000',
    }),
    multiValue: (styles) => ({
      ...styles,
      fontSize: '15px',
      color: '#000',
    }),
    menu: (styles, state) => ({
      ...styles,
      zIndex: '99',
    }),
  };
  return (
    <div
      style={{ width: width }}
      className={classnames('input-cntr', className)}
    >
      <label className="label" htmlFor={name}>
        {label}
        {required ? <span className="text-danger">*</span> : null}
      </label>
      <div style={{ marginTop: label ? '10px' : '0px' }} className="inner">
        <ReactSelect
          options={options}
          value={value}
          isDisabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          styles={customStyle}
          isClearable={clearable}
          error={error}
          loadOptions={loadOptions}
          cacheOptions={cacheOptions}
          noOptionsMessage={() => 'Start typing to search'}
          onInputChange={onInputChange}
          isMulti={isMulti}
        />
        {error ? (
          <small className="invalid-feedback">
            <i className="fas fa-times" />
            {error}
          </small>
        ) : (
          helperText && (
            <small className="helper-text">
              <i className="fas fa-info-circle" />
              {helperText}
            </small>
          )
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  error: PropTypes.string,
};

Select.defaultProps = {
  value: null,
  required: false,
  disabled: false,
  helperText: null,
  placeholder: undefined,
  clearable: true,
  className: '',
  isMulti: false,
  error: null,
};

export default Select;
