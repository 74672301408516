import { Stack } from '@mui/material';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import instance from 'src/http';
import {
  areNullableFloatsEqual,
  isEmpty,
  normalizeFloatNullable,
} from '../../../utils';
import AsyncSelect from '../../Inputs/AsyncSelect';
import Button from '../../Inputs/Button';
import DatePicker from '../../Inputs/DatePicker';
import ImageSelect from '../../Inputs/ImageSelect';
import Select from '../../Inputs/Select';
import Spinner from '../../Inputs/Spinner';
import TextField from '../../Inputs/TextField';
import Wysiwyg from '../../Inputs/Wysiwyg/Wysiwyg';
import Header from '../../Layout/Header';
import LanguageSwitcher from '../../Layout/LanguageSwitcher';
import Pagination from '../../Layout/Pagination';
import css from './Business.module.scss';
import { BusinessCashbackScheduler } from './BusinessCashbackScheduler';
import { BusinessCashbackSplitage } from './BusinessCashbackSplitage';
import BusinessDiscountsList from './BusinessDiscountsList';

const initialState = {
  name: '',
  label: '',
  loading: false,
  type: '',
  results: '',
  totalResults: 0,
  page: 1,
  discounts: [],
  selectedTab: 'Business',
  isAffiliate: true,
  localBusinessType: null,
  affiliateOptions: [
    { label: 'Affiliate', value: 'Affiliate' },
    { label: 'National', value: 'National' },
    { label: 'Regional', value: 'Regional' },
    { label: 'Municipal', value: 'Municipal' },
    { label: 'Local', value: 'Local' },
  ],
  published: false,
  publishedOptions: [
    { label: 'Published', value: true },
    { label: 'Not Published', value: false },
  ],
  startDate: null,
  endDate: null,
  thumbnail: null,
  thumbnail_fr: null,
  discountErrors: {},
  address: '',
  postalCode: '',
  city: '',
  appartment: '',
  country: '',
  province: '',
  tab: 'en',
  en: {
    name: '',
    description: '',
    excerpt: '',
  },
  fr: {
    name: '',
    description: '',
    excerpt: '',
  },
  advertisingId: '',
  affiliateProgramIdValue: '',
  affiliateProgramId: { label: 'None', value: null },
  affiliateProgramOptions: null,
  errors: {},
  cashbackSplitOptionError: false,
  loadingAffiliateProgramOptions: true,
  userCashbackPercentage: null,
  systemWideCashbackUserSplitPercentage: null,
  hasBusinessOverride: false,
  originalPercentage: null,
  lng: null,
  lat: null,
  ownerId: null,
  website: null,
  editAffiliate: false,
};

export class Business extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = initialState;
  }

  componentDidMount() {
    const { mode, id, page } = this.props.match.params;

    if (mode === 'edit' && id) {
      this.setState({ loading: true });
      this.getBusinessesbyId(id);
      this.getBusinessesUserCashbackSplitageById(id);
      this.getDiscountsById(id);
      this.getSystemWideCashbackUserSplitPercentage();
    } else this.getAffiliatePrograms();

    if (page && page === 1 && id) {
      this.setState({ selectedTab: 'Discount' });
    }
    if (page && page === 2 && id) {
      this.setState({ selectedTab: 'Splits' });
    }
  }

  // Reset state when component switches to ADD
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.url !== this.props.match.url &&
      this.props.match.url.includes('add')
    ) {
      this.setState({ ...initialState });
    }
  }

  componentWillUnmount() {
    this.setState({ ...initialState });
  }

  getAffiliatePrograms = async () => {
    const id = this.state.affiliateProgramIdValue;
    try {
      const res = await instance.get('/affiliates');
      const programData = [
        { label: 'None', value: null },
        ...res.data.map((el) => ({
          label: el.name,
          value: el.id,
        })),
      ];
      const affiliateProgramId = id
        ? programData.find((el) => el.value === id)
        : { label: 'None', value: null };
      this.setState({
        affiliateProgramOptions: programData,
        loadingAffiliateProgramOptions: false,
        affiliateProgramId,
        tempAffiliateProgramId: affiliateProgramId,
      });
    } catch (err) {
      this.setState({ loadingAffiliateProgramOptions: false });
      console.log(err);
    }
  };

  getSystemWideCashbackUserSplitPercentage = async () => {
    try {
      const res = await instance.get('/system/user-cashback-percentage');
      this.setState({
        systemWideCashbackUserSplitPercentage: `${res.data}`,
      });
    } catch (err) {
      this.setState({
        systemWideCashbackUserSplitPercentage: null,
        cashbackSplitOptionError: true,
      });
      console.log(err);
    }
  };

  fetchUsers = async (inputValue) => {
    const url = '/users';
    const options = {
      dropdown: true,
      search: inputValue,
    };

    try {
      const res = await instance.get(url, { params: options });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  createOptionsArrayFromString = (string) => ({ label: string, value: string });

  updateState = (business) => {
    this.setState({
      en: {
        name: business.name,
        description: business.description,
        excerpt: business.excerpt ?? '',
      },
      fr: {
        name: business.name_fr,
        description: business.description_fr,
        excerpt: business.excerpt_fr ?? '',
      },
      label: business.label,
      type: { label: business.type, value: business.type },
      description: business.description,
      address: !isEmpty(business.address)
        ? { label: business.address, value: business.address }
        : null,
      published: {
        label: business.published === true ? 'Published' : 'Not Published',
        value: business.published,
      },
      startDate: business.startDate
        ? moment(business.startDate).startOf('day').toDate()
        : null,
      endDate: business.endDate
        ? moment(business.endDate).endOf('day').toDate()
        : null,
      thumbnail: business.thumbnail,
      thumbnail_fr: business.thumbnail_fr,
      city: business.city,
      country: business.country,
      province: business.province,
      postalCode: business.postalCode,
      loading: false,
      advertisingId: business.advertisingId,
      affiliateProgramIdValue: business.affiliateProgramId,
      lng: business.lng,
      lat: business.lat,
      ownerId: business.owner && {
        value: business.owner.id,
        label: `${business.owner.firstName} ${business.owner.lastName} (${business.owner.email})`,
      },
      isAffiliate: business.type !== 'Local',
      localBusinessType: this.createOptionsArrayFromString(
        business?.localBusinessType
      ),
      website: business.website,
    });
    this.getAffiliatePrograms();
  };

  onCashbackSplitageChange = ({
    userCashbackPercentage,
    hasBusinessOverride,
  }) => {
    this.setState({ userCashbackPercentage, hasBusinessOverride });
  };

  changeTabs = (tab) => {
    this.setState({ tab: tab });
  };

  onChange = (e) => {
    const { tab } = this.state;
    this.setState({
      [tab]: { ...this.state[tab], [e.target.name]: e.target.value },
    });
  };

  handleWysiwyg = (content) => {
    const { tab } = this.state;
    this.setState({
      [tab]: {
        ...this.state[tab],
        description: content,
      },
    });
  };

  handleFileChange = (file, name) => {
    this.setState({ [name]: file });
  };

  handleUpdateAffiliate = async () => {
    const { id } = this.props.match.params;
    const { tempAffiliateProgramId } = this.state;
    this.setState({ loading: true });
    const res = await instance.put(`/businesses/${id}/affiliateNetwork/`, {
      affiliateProgramId: tempAffiliateProgramId.value,
    });
    this.setState({
      affiliateProgramId: tempAffiliateProgramId,
      editAffiliate: false,
      loading: false,
    });
  };

  handleSave = async () => {
    const { history } = this.props;
    const { mode, id } = this.props.match.params;
    const {
      label,
      type,
      address,
      published,
      startDate,
      endDate,
      en,
      fr,
      thumbnail,
      thumbnail_fr,
      city,
      country,
      province,
      postalCode,
      advertisingId,
      affiliateProgramId,
      hasBusinessOverride,
      userCashbackPercentage,
      originalPercentage,
      lng,
      lat,
      ownerId,
      localBusinessType,
      website,
    } = this.state;
    const requestMethod = mode === 'edit' ? 'put' : 'post';
    const path = mode === 'edit' ? `/businesses/${id}` : '/businesses';

    const payload = {
      label,
      type: type.value,
      name: en.name,
      name_fr: fr.name,
      description: en.description,
      description_fr: fr.description,
      excerpt: en.excerpt,
      excerpt_fr: fr.excerpt,
      published: published.value,
      startDate: moment(startDate).startOf('day'),
      endDate: moment(endDate).endOf('day'),
      thumbnail: !isEmpty(thumbnail) ? thumbnail.id : null,
      thumbnail_fr: !isEmpty(thumbnail_fr) ? thumbnail_fr.id : null,
      address: !isEmpty(address) ? address.label : null,
      city: city,
      country: country,
      province: province,
      postalCode: postalCode,
      advertisingId: advertisingId,
      affiliateProgramId: affiliateProgramId ? affiliateProgramId.value : null,
      address_geolocation: lng && lat ? { lng, lat } : null,
      ownerId: ownerId && ownerId.value,
      localBusinessType: localBusinessType?.value,
      website: website ? website : undefined,
    };

    try {
      const res = await instance[requestMethod](path, payload);
      const businessId = res.data.id;
      try {
        const targetPercentage = hasBusinessOverride
          ? userCashbackPercentage || '0'
          : null;
        if (
          (requestMethod === 'post' && targetPercentage) ||
          (requestMethod === 'put' &&
            !areNullableFloatsEqual(targetPercentage, originalPercentage))
        ) {
          await instance.post(
            `/businesses/${businessId}/user-cashback-splitage`,
            {
              userPercentage: targetPercentage,
            }
          );
        }
        history.push('/dashboard/businesses');
      } catch (error) {
        const obj = error.response.data;
        if (obj.userPercentage) {
          obj.userCashbackPercentage = obj.userPercentage;
        }
        this.setState({ errors: obj, fileUploading: false });
        this.myRef.current.scrollIntoView();
      }
    } catch (error) {
      const obj = error.response.data;
      if (
        obj.hasOwnProperty('name_fr') ||
        obj.hasOwnProperty('description_fr') ||
        obj.hasOwnProperty('excerpt_fr')
      ) {
        this.setState({ tab: 'fr' });
      } else this.setState({ tab: 'en' });
      this.setState({ errors: error.response.data, fileUploading: false });
      this.myRef.current.scrollIntoView();
    }
  };

  handleFileUpload = (imgType, file) => {
    this.setState({ [imgType]: file });
  };

  handleSelectAffiliate = (type) => {
    if (!type) return this.setState({ type: '' });
    this.setState({ type: type });
    if (type.value === 'Local') {
      this.setState({ isAffiliate: false });
    } else {
      this.setState({ isAffiliate: true });
    }
  };

  handleSelectPublished = (published) => {
    this.setState({ published: published });
  };

  handleSelectImage = (image, name) => {
    this.setState({ [name]: image });
  };

  handleTextField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleStartDate = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDate = (endDate) => {
    const { startDate } = this.state;
    this.setState({ endDate });

    if (moment(startDate).isAfter(endDate)) {
      this.setState({ startDate: endDate });
    }
  };

  changePage = (page) => {
    const { id } = this.props.match.params;
    this.getDiscountsById(id, page);
    this.setState({ page });
    this.props.history.replace(`/dashboard/businesses/${id}/edit/${page}`);
  };

  getDiscountsById = (id, page) => {
    this.setState({ loading: true });
    instance
      .get(`/businesses/${id}/offers`, {
        params: { page: page ? page : 1 },
      })
      .then((res) => {
        this.setState({
          discounts: res.data.results,
          totalResults: res.data.totalResults,
          loading: false,
          discountErrors: {},
        });
      })
      .catch((err) => {
        this.setState({ discountErrors: err.response.data, loading: false });
      });
  };

  getBusinessesbyId = (id) => {
    instance
      .get(`/businesses/${id}`)
      .then((res) => {
        this.updateState(res.data);
      })
      .catch((err) => {
        this.setState({ errors: err.response });
      });
  };

  getBusinessesUserCashbackSplitageById = async (id) => {
    try {
      const res = await instance.get(
        `/businesses/${id}/user-cashback-splitage`
      );
      const userCashbackPercentage = normalizeFloatNullable(
        res.data.userPercentage
      );
      this.setState({
        userCashbackPercentage,
        originalPercentage: userCashbackPercentage,
        hasBusinessOverride: res.data.userPercentage != null,
      });
    } catch (err) {
      this.setState({
        userCashbackPercentage: null,
        originalPercentage: null,
        errors: {
          ...this.state.errors,
          userCashbackPercentage:
            'Error getting business wide user cashback splitage',
        },
      });
      console.log(err);
    }
  };

  handleSelectBusinessTab = () => {
    this.setState({ selectedTab: 'Business', errors: {} });
    this.props.history.replace(
      `/dashboard/businesses/${this.props.match.params.id}/edit/`
    );
  };

  handleSelectDiscountTab = () => {
    this.setState({ selectedTab: 'Discount', errors: {} });
    this.props.history.replace(
      `/dashboard/businesses/${this.props.match.params.id}/edit/1`
    );
  };

  handleSelectSplitSchedulerTab = () => {
    this.setState({ selectedTab: 'Splits', errors: {} });
    this.props.history.replace(
      `/dashboard/businesses/${this.props.match.params.id}/edit/2`
    );
  };

  handleSelectSettingsTab = () => {
    this.setState({ selectedTab: 'Settings', errors: {} });
    this.props.history.replace(
      `/dashboard/businesses/${this.props.match.params.id}/edit/3`
    );
  };

  loadAddressOptions = async (inputValue) => {
    const url = '/locations/autocomplete';
    const params = { params: { input: inputValue } };

    try {
      const res = await instance.get(url, params);
      return res.data.map((el) => {
        return {
          value: el.place_id,
          label: `${el.address}, ${el.additionalAddress}`,
          ...el,
        };
      });
    } catch (err) {
      this.setState({ errors: err.response.data });
    }
  };
  fetchPlaceData = async (address) => {
    const url = `/locations/${address.place_id}/details`;
    try {
      const res = await instance.get(url);
      this.setState({
        address: {
          label: address.address,
          value: address.place_id,
          ...res.data,
        },
        city: res.data.city ? res.data.city : '',
        country: res.data.country ? res.data.country : '',
        province: res.data.province ? res.data.province : '',
        postalCode: res.data.postalCode ? res.data.postalCode : '',
        lng: res.data.lng || null,
        lat: res.data.lat || null,
      });
    } catch (error) {
      this.setState({ addressErrors: error.response.data });
    }
  };

  handleAddressSelect = (address) => {
    this.fetchPlaceData(address);
  };

  handleSelectAffiliateProgram = (val) => {
    this.setState({ affiliateProgramId: val });
  };

  handleSelectAffiliateProgramOnEdit = (val) => {
    this.setState({ tempAffiliateProgramId: val });
  };

  onChangeAdvertId = (e) => {
    this.setState({ advertisingId: e.target.value });
  };

  handleDelete = async () => {
    const { id } = this.props.match.params;
    try {
      const res = await instance.delete(`/businesses/${id}`);
      this.props.history.push('/dashboard/businesses');
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
  };

  handleDeleteBtn = () => {
    if (this.state.deleteMode) {
      return (
        <div style={{ display: 'flex' }}>
          <Button
            styles={['btn', 'btn_red']}
            text="Confirm"
            onClick={this.handleDelete}
          />
          <Button
            text="Cancel"
            onClick={() => this.setState({ deleteMode: false })}
          />
        </div>
      );
    }
  };

  handleLocalBusinessTypeSelect = (v) => {
    this.setState({ localBusinessType: v });
  };

  render() {
    const { history } = this.props;
    const {
      label,
      tab,
      errors,
      loading,
      en,
      fr,
      deleteMode,
      type,
      selectedTab,
      isAffiliate,
      address,
      affiliateOptions,
      published,
      publishedOptions,
      startDate,
      endDate,
      thumbnail,
      thumbnail_fr,
      postalCode,
      city,
      appartment,
      country,
      province,
      advertisingId,
      affiliateProgramId,
      affiliateProgramOptions,
      userCashbackPercentage,
      systemWideCashbackUserSplitPercentage,
      hasBusinessOverride,
      ownerId,
      cashbackSplitOptionError,
      localBusinessType,
      website,
      editAffiliate,
      tempAffiliateProgramId,
    } = this.state;

    const { mode, id } = this.props.match.params;

    let nameErr;
    let descriptionErr;
    let excerptErr;

    if (!isEmpty(errors)) {
      nameErr = tab === 'fr' ? errors.name_fr : errors.name;
      descriptionErr =
        tab === 'fr' ? errors.description_fr : errors.description;
      excerptErr = tab === 'fr' ? errors.excerpt_fr : errors.excerpt;
    }

    const tabActiveStyle = {
      borderColor: 'rgba(33, 134, 235, 1)',
      color: 'rgba(33, 134, 235, 1)',
      fontWeight: '500',
    };

    const renderAddressFieldsIfLocalBusinessTypeIsPhysical = () => {
      if (!isAffiliate && localBusinessType?.value === 'Physical') {
        return (
          <>
            <AsyncSelect
              value={address}
              name="address"
              label="Address"
              placeholder="Address"
              required
              className="input-lg"
              loadOptions={this.loadAddressOptions}
              onChange={this.handleAddressSelect}
              clearable={false}
              error={errors.address}
            />

            <TextField
              name="appartment"
              value={appartment}
              label="Appartment, unit, suite or floor"
              onChange={this.handleTextField}
              error={errors.appartment}
            />
            <TextField
              name="city"
              value={city}
              label="City"
              onChange={this.handleTextField}
              error={errors.city}
            />
            <TextField
              name="province"
              value={province}
              label="Province"
              onChange={this.handleTextField}
              error={errors.province}
            />
            <TextField
              name="postalCode"
              value={postalCode}
              label="Postal Code"
              onChange={this.handleTextField}
              error={errors.postalCode}
            />
            <TextField
              value={country}
              name="country"
              label="Country"
              onChange={this.handleTextField}
              error={errors.country}
            />
          </>
        );
      }
    };

    const renderWebsiteFieldIfLocalBusinessTypeIsOnlineOnly = () => {
      if (!isAffiliate && localBusinessType?.value === 'Online only')
        return (
          <TextField
            value={website}
            name="website"
            label="Website"
            placeholder="Website"
            className="input-lg"
            onChange={this.handleTextField}
            error={errors.website}
          />
        );
    };

    const renderSelectBusinessTypeIfBusinessIsLocal = () => {
      const options = ['Physical', 'Online only'];
      const localBusinessTypeOptions = options.map((el) => ({
        value: el,
        label: el,
      }));

      if (!isAffiliate)
        return (
          <Select
            onChange={(v) => this.handleLocalBusinessTypeSelect(v)}
            label={'Local Business Type'}
            options={localBusinessTypeOptions}
            name={'localBusinessType'}
            value={localBusinessType}
            clearable={false}
            error={errors.localBusinessType}
          />
        );
    };

    const renderSelectOwnerIdIfBusinessIsLocal = () => {
      if (!isAffiliate)
        return (
          <AsyncSelect
            name="userId"
            label="Owner"
            loadOptions={this.fetchUsers}
            value={ownerId}
            onChange={(value) => this.setState({ ownerId: value })}
            error={errors.ownerId}
          />
        );
    };

    if (!loading) {
      return (
        <div className={css.businessContainer}>
          <Header
            title={
              mode === 'add'
                ? 'New Business'
                : tab === 'en'
                ? en.name
                : fr.name
                ? fr.name
                : en.name
            }
            btnText={selectedTab === 'Discount' ? 'New Discount' : undefined}
            btnLink
            url={
              selectedTab === 'Discount'
                ? `/dashboard/discounts/${id}/list/`
                : ''
            }
          />

          <LanguageSwitcher language={tab} changeLanguage={this.changeTabs} />
          <div
            style={{
              display: 'flex',
            }}
          >
            {mode === 'edit' ? (
              <>
                <span
                  style={
                    selectedTab === 'Business' ? tabActiveStyle : undefined
                  }
                  className={css.tab}
                  onClick={this.handleSelectBusinessTab}
                >
                  Business details
                </span>
                <span
                  style={
                    selectedTab === 'Discount' ? tabActiveStyle : undefined
                  }
                  className={css.tab}
                  onClick={this.handleSelectDiscountTab}
                >
                  Discounts
                </span>
                <span
                  style={selectedTab === 'Splits' ? tabActiveStyle : undefined}
                  className={css.tab}
                  onClick={this.handleSelectSplitSchedulerTab}
                >
                  Cashback Split Scheduler
                </span>
                <span
                  style={
                    selectedTab === 'Settings' ? tabActiveStyle : undefined
                  }
                  className={css.tab}
                  onClick={this.handleSelectSettingsTab}
                >
                  Settings
                </span>
              </>
            ) : null}
          </div>

          {selectedTab === 'Business' && (
            <div>
              <TextField
                value={advertisingId}
                name="advertisingId"
                label="Advertising Id"
                placeholder="Advertising Id"
                required
                className="input-lg"
                onChange={this.onChangeAdvertId}
                error={errors.advertisingId}
              />
              {mode !== 'edit' ? (
                <Select
                  value={affiliateProgramId}
                  name="affiliateProgramId"
                  label="Affiliate Program ID"
                  placeholder="affiliateProgramId"
                  options={affiliateProgramOptions}
                  className="input-lg"
                  onChange={this.handleSelectAffiliateProgram}
                  error={errors.affiliateProgramId}
                />
              ) : null}
              <TextField
                value={tab === 'en' ? en.name : fr.name}
                name="name"
                label="Business name"
                placeholder="Business name"
                required={tab === 'en'}
                className="input-lg"
                onChange={this.onChange}
                error={nameErr}
              />

              <TextField
                value={label}
                name="label"
                label="Business Label"
                placeholder="Business label"
                required
                className="input-lg"
                onChange={this.handleTextField}
                error={errors.label}
              />

              <TextField
                value={tab === 'en' ? en.excerpt : fr.excerpt}
                name="excerpt"
                label={`Business Excerpt (${tab})`}
                placeholder="Short Description"
                required
                className="input-lg"
                onChange={this.onChange}
                error={excerptErr}
              />

              <Select
                value={type}
                name="Business type"
                label="Business type"
                placeholder="Business type"
                options={affiliateOptions}
                className="input-lg"
                onChange={this.handleSelectAffiliate}
                error={errors.type}
                clearable={false}
              />
              {renderSelectOwnerIdIfBusinessIsLocal()}
              {renderSelectBusinessTypeIfBusinessIsLocal()}
              {renderAddressFieldsIfLocalBusinessTypeIsPhysical()}
              {renderWebsiteFieldIfLocalBusinessTypeIsOnlineOnly()}

              <DatePicker
                name="startDate"
                selected={startDate}
                label="Start date"
                onChange={this.handleStartDate}
                error={errors.startDate}
                maxDate={endDate}
                isClearable={true}
              />

              <DatePicker
                name="endDate"
                selected={endDate}
                label="End date"
                onChange={this.handleEndDate}
                error={errors.endDate}
                minDate={new Date()}
                isClearable={true}
              />

              <Select
                value={published}
                name="Published status"
                label="Published"
                options={publishedOptions}
                className="input-lg"
                onChange={this.handleSelectPublished}
                error={errors.published}
              />

              <Wysiwyg
                name="description"
                label="Business description"
                val={tab === 'en' ? en.description : fr.description}
                onChange={this.handleWysiwyg}
                error={descriptionErr}
              />

              <ImageSelect
                initialImage={thumbnail}
                label="Thumbnail (en)"
                name="thumbnail"
                type="Thumbnail"
                handleSelect={this.handleSelectImage}
                error={errors.thumbnail}
              />
              <ImageSelect
                initialImage={thumbnail_fr}
                label="Thumbnail (fr)"
                name="thumbnail_fr"
                type="Thumbnail"
                handleSelect={this.handleSelectImage}
                error={errors.thumbnail_fr}
              />

              <BusinessCashbackSplitage
                userCashbackPercentage={userCashbackPercentage}
                systemWideCashbackUserSplitPercentage={
                  systemWideCashbackUserSplitPercentage
                }
                hasBusinessOverride={hasBusinessOverride}
                onStateChange={this.onCashbackSplitageChange}
                cashbackSplitOptionError={cashbackSplitOptionError}
                errors={errors}
              />

              <div className="btn_appart">
                <Button
                  text={mode === 'edit' ? 'Save' : 'Create Business'}
                  onClick={this.handleSave}
                />
                {mode === 'edit' ? (
                  deleteMode ? (
                    this.handleDeleteBtn()
                  ) : (
                    <Button
                      onClick={() => this.setState({ deleteMode: true })}
                      text="Delete"
                      styles={['btn', 'btn_red']}
                    />
                  )
                ) : null}
              </div>

              {!isEmpty(errors) ? (
                <span ref={this.myRef} className="error_msg">
                  There's an error, please check your input.
                </span>
              ) : null}
            </div>
          )}
          {selectedTab === 'Discount' && (
            <div>
              <BusinessDiscountsList
                discounts={this.state.discounts}
                errors={this.state.discountErrors}
                loading={loading}
                businessId={id}
              />
              {this.state.totalResults ? (
                <Pagination
                  totalRecords={this.state.totalResults}
                  page={this.state.page}
                  onPageChange={this.changePage}
                />
              ) : null}
            </div>
          )}
          {selectedTab === 'Splits' && (
            <BusinessCashbackScheduler
              businessId={this.props.match.params.id}
            />
          )}
          {selectedTab === 'Settings' && (
            <Stack
              sx={{ mt: editAffiliate ? 2 : 5 }}
              direction="row"
              spacing={2}
              alignItems="baseline"
            >
              {!editAffiliate ? (
                <>
                  <div>{affiliateProgramId.label}</div>
                  <Button
                    onClick={() => {
                      this.setState({ editAffiliate: true });
                    }}
                    text="Change Affiliate"
                  />
                </>
              ) : (
                <>
                  <Select
                    value={tempAffiliateProgramId}
                    name="affiliateProgramId"
                    placeholder="affiliateProgramId"
                    options={affiliateProgramOptions}
                    className="input-lg"
                    onChange={this.handleSelectAffiliateProgramOnEdit}
                  />
                  <Button
                    confirmationText="Confirm affiliate program update for this business?"
                    disabled={
                      !tempAffiliateProgramId ||
                      tempAffiliateProgramId.value === affiliateProgramId.value
                    }
                    onClick={this.handleUpdateAffiliate}
                    needConfirm
                    text="Save"
                  />
                </>
              )}
            </Stack>
          )}
        </div>
      );
    }
    if (loading) return <Spinner />;
  }
}

export default withRouter(Business);
