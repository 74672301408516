import axios from 'axios';

export const API_URL = `${process.env.REACT_APP_DOMAIN}`;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers['x-auth-token'] = `${localStorage.getItem('token')}`;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._isRetry &&
      !error.config.url.includes('refresh')
    ) {
      originalRequest._isRetry = true;
      try {
        // API call for updating token
        const token = localStorage.getItem('refreshToken');
        if (token) {
          const { data } = await instance.post('/users/refresh', {
            refreshToken: token,
          });
          localStorage.setItem('refreshToken', data.refreshToken);
          localStorage.setItem('token', data.token);
          originalRequest.headers['x-auth-token'] = `${data.token}`;
        }
        return instance.request(originalRequest);
      } catch (error) {
        console.error(error);
      }
    }
    throw error;
  }
);

export default instance;
