import React, { useCallback, useEffect, useState } from 'react';
import instance from 'src/http';
import { normalizeFloat } from '../../../utils';
import Button from '../../Inputs/Button';
import Spinner from '../../Inputs/Spinner';
import TextField from '../../Inputs/TextField';
import css from './System.module.scss';

const isPercentageAllowed = (values) => {
  const { value } = values;
  return value >= 0 && value <= 100;
};

export default function CashbackPercentage() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [userSharePercentage, setUserSharePercentage] = useState('0');
  const [isNewPercentage, setIsNewPercentage] = useState(true);
  const [companySharePercentage, setCompanySharePercentage] = useState('100');
  const onNumericsBlur = useCallback(() => {
    const realUserSharePercentage = normalizeFloat(userSharePercentage || '0');
    const realCompanySharePercentage = normalizeFloat(
      100 - parseFloat(realUserSharePercentage)
    );
    setUserSharePercentage(realUserSharePercentage);
    setCompanySharePercentage(realCompanySharePercentage);
  }, [userSharePercentage]);
  const userShareChangeHandler = useCallback(
    (values) => {
      const { value } = values;
      const newUserSharePercentage = value ? normalizeFloat(value) : value;
      setUserSharePercentage(newUserSharePercentage);
      if (value === '100' && !companySharePercentage) {
        return;
      }
      setCompanySharePercentage(normalizeFloat(100 - parseFloat(value || '0')));
    },
    [companySharePercentage]
  );
  const companyShareChangeHandler = useCallback(
    (values) => {
      const { value } = values;
      setCompanySharePercentage(value ? normalizeFloat(value) : value);
      if (value === '100' && !userSharePercentage) {
        return;
      }
      const newUserSharePercentage = normalizeFloat(
        100 - parseFloat(value || '0')
      );
      setUserSharePercentage(newUserSharePercentage);
    },
    [userSharePercentage]
  );
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const res = await instance.get(`/system/user-cashback-percentage`);
        setIsNewPercentage(!res.data);
        setUserSharePercentage(res.data);
      } catch (err) {
        setError({ message: 'Cannot fetch system wide cashback' });
        console.error(err);
      }
      setIsLoading(false);
    };
    setError({});
    fetchInitialData();
    return () => {};
  }, []);
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const userPercentage = userSharePercentage || '0';
      const method = isNewPercentage ? 'post' : 'put';
      console.log(`userPercentage: "${userPercentage}"`);
      await instance[method](`/system/user-cashback-percentage`, {
        systemWideCashbackPercentage: userPercentage,
      });
      setIsNewPercentage(false);
      setError({});
    } catch (error) {
      const baseError = { message: 'Error saving system settings' };
      console.error(error);
      if (error.response?.data) {
        setError({ ...error.response.data, ...baseError });
      } else {
        setError(baseError);
      }
    }
    setIsLoading(false);
  }, [isNewPercentage, userSharePercentage]);
  return (
    <>
      <TextField
        name="userShare"
        value={userSharePercentage}
        label="User Share"
        onValueChange={userShareChangeHandler}
        onChange={() => {}}
        type="number"
        format={null}
        decimalScale={2}
        allowNegative={false}
        suffix="%"
        isAllowed={isPercentageAllowed}
        className={css.editControl}
        disabled={isLoading}
        allowEmptyFormatting={true}
        onNumericBlur={onNumericsBlur}
        error={error?.systemWideCashbackPercentage}
      />

      <TextField
        name="companyShare"
        value={companySharePercentage}
        label="Company Share"
        onValueChange={companyShareChangeHandler}
        onChange={() => {}}
        type="number"
        format={null}
        decimalScale={2}
        allowNegative={false}
        suffix="%"
        isAllowed={isPercentageAllowed}
        className={css.editControl}
        disabled={isLoading}
        allowEmptyFormatting={true}
        onNumericBlur={onNumericsBlur}
      />
      {error?.message && <div className="error_msg">{error.message}</div>}
      {isLoading && <Spinner className={css.editSpinner} />}
      <Button
        onClick={handleSubmit}
        text="Save"
        needConfirm
        confirmationText="You are about to change the split percentage for the whole system"
        disabled={isLoading}
      ></Button>
    </>
  );
}
