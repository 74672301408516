/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getCategories,
  deleteCategory,
  clearErrors,
} from '../../../redux/actions/categoryActions';
import TextField from '../../Inputs/TextField';
import { isEmpty } from '../../../utils';
import style from './Category.module.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import ImageSelect from '../../Inputs/ImageSelect';
import instance from 'src/http';

export class Category extends Component {
  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.state = {
      name: '',
      name_fr: '',
      createMode: false,
      deleteMode: false,
      dialogId: '',
      dialog: '',
      description: '',
      description_fr: '',
      headerImage: null,
      headerImageMobile: null,
      headerImage_fr: null,
      headerImageMobile_fr: null,
      thumbnail: null,
      thumbnail_fr: null,
      dialogOpen: false,
      confirmDialogOpen: false,
      errors: {},
    };
  }

  componentDidMount() {
    const { category } = this.props;

    if (isEmpty(category)) {
      this.setState({ createMode: true });
    } else {
      this.setState({
        name: category.name,
        name_fr: category.name_fr,
        description: category.description,
        description_fr: category.description_fr,
        headerImage: category.headerImage,
        headerImageMobile: category.headerImageMobile,
        thumbnail: category.thumbnail,
        headerImage_fr: category.headerImage_fr,
        headerImageMobile_fr: category.headerImageMobile_fr,
        thumbnail_fr: category.thumbnail_fr,
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };
  closeDialog = () => {
    const initialState = {
      name: '',
      name_fr: '',
      dialogId: '',
      dialog: '',
      description: '',
      description_fr: '',
      headerImage: null,
      headerImageMobile: null,
      headerImage_fr: null,
      headerImageMobile_fr: null,
      thumbnail: null,
      thumbnail_fr: null,
      dialogOpen: false,
      errors: {},
    };
    if (!this.state.createMode) {
      this.setState({ dialogOpen: false });
    } else {
      this.setState(initialState);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      createMode,
      name,
      name_fr,
      description,
      description_fr,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      thumbnail,
      thumbnail_fr,
    } = this.state;
    const { category, getCategories, parentId, match } = this.props;

    try {
      const payload = {
        name,
        name_fr,
        description,
        description_fr,
        headerImage: !isEmpty(headerImage) ? headerImage.id : undefined,
        headerImage_fr: !isEmpty(headerImage_fr)
          ? headerImage_fr.id
          : undefined,
        headerImageMobile: !isEmpty(headerImageMobile)
          ? headerImageMobile.id
          : undefined,
        headerImageMobile_fr: !isEmpty(headerImageMobile_fr)
          ? headerImageMobile_fr.id
          : undefined,
        thumbnail: !isEmpty(thumbnail) ? thumbnail.id : undefined,
        thumbnail_fr: !isEmpty(thumbnail_fr) ? thumbnail_fr.id : undefined,
        parentId,
      };
      if (parentId) payload.parentId = parentId;
      let path = parentId ? `/subcategories/` : `/categories/`;
      const method = createMode ? 'post' : 'put';
      if (!createMode) path = path + category.id;
      const res = await instance[method](path, payload);
      getCategories(match.params.page ? match.params.page : undefined);
      this.closeDialog();
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
  };

  resetState = () => {
    this.setState({
      name: '',
      name_fr: '',
      createMode: false,
      deleteMode: false,
      dialogId: '',
      dialog: '',
      description: '',
      description_fr: '',
      headerImage: null,
      headerImageMobile: null,
      headerImage_fr: null,
      headerImageMobile_fr: null,
      thumbnail: null,
      thumbnail_fr: null,
    });
  };

  setDeleteMode = (mod) => {
    this.setState({ deleteMode: mod });
  };

  handleDelete = async (e, dialog) => {
    e.preventDefault();
    const { category, deleteCategory, parentId } = this.props;
    deleteCategory(this.closeDialog, category.id, parentId);
  };

  handleCancel = () => {
    const { createMode } = this.state;
    const { category, clearErrors } = this.props;
    if (createMode) {
      this.setState({
        name: '',
        name_fr: '',
        description: '',
        description_fr: '',
        headerImage: null,
        headerImageMobile: null,
        headerImage_fr: null,
        headerImageMobile_fr: null,
        thumbnail: null,
        thumbnail_fr: null,
        errors: {},
      });
    } else {
      this.setState({
        name: category.name,
        name_fr: category.name_fr,
        description: category.description,
        description_fr: category.description_fr,
        headerImage: category.headerImage,
        headerImageMobile: category.headerImageMobile,
        headerImage_fr: category.headerImage_fr,
        headerImageMobile_fr: category.headerImageMobile_fr,
        thumbnail: category.thumbnail,
        thumbnail_fr: category.thumbnail_fr,
      });
    }
    this.closeDialog();
    clearErrors();
  };

  handleHeaderImageChange = (headerImage) => {
    this.setState({ headerImage });
  };
  handleThumbnailChange = (thumbnail) => {
    this.setState({ thumbnail });
  };
  handleHeaderImageFRChange = (headerImage_fr) => {
    this.setState({ headerImage_fr });
  };
  handleThumbnailFRChange = (thumbnail_fr) => {
    this.setState({ thumbnail_fr });
  };
  handleFileUpload = (imgType, file) => {
    this.setState({ [imgType]: file });
  };

  openConfirmDialog = () => {
    this.setState({ confirmDialogOpen: true });
  };
  closeConfirmDialog = () => {
    this.setState({ confirmDialogOpen: false });
  };
  handleSelectImage = (image, name) => {
    this.setState({ [name]: image });
  };

  handleRemoveImage = (image, name) => {
    this.setState({ [name]: null });
  };
  render() {
    const {
      createMode,
      deleteMode,
      name,
      name_fr,
      description,
      description_fr,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      thumbnail,
      thumbnail_fr,
      dialogOpen,
      confirmDialogOpen,
      errors,
    } = this.state;
    const { category, parentId } = this.props;
    const errs = this.props.errors;

    let button = (
      <Button onClick={this.openDialog}>
        {parentId ? 'New Subcategory' : 'New Category'}
      </Button>
    );
    let submitBtnText = 'Create';

    if (!createMode) {
      button = (
        <i title="edit" className="far fa-edit" onClick={this.openDialog}></i>
      );
      submitBtnText = 'Save';
    }

    let buttons = (
      <div className={style.btn_container}>
        <Button onClick={(e) => this.handleSubmit(e)} text={submitBtnText} />
        <Button
          text="Delete"
          onClick={() => this.setDeleteMode(true)}
          styles={['btn_link', 'btn_link_red']}
        />
      </div>
    );

    let dialogMode = createMode ? 'New' : 'Edit';
    let entityType = parentId ? 'Subcategory' : 'Category';

    return (
      <div>
        {button}

        <Dialog
          open={dialogOpen}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          disableBackdropClick
        >
          <div className={style.container}>
            <DialogTitle id="alert-dialog-title">
              <div className={style.dialog_header}>
                <h3>{`${dialogMode} ${entityType}`}</h3>
                <i
                  onClick={this.closeDialog}
                  className="fas fa-times closeDialog"
                ></i>
              </div>
            </DialogTitle>
            <div className={style.form}>
              <div className={style.col}>
                <TextField
                  name="name"
                  label="Category name (English)"
                  placeholder="Category name (English)"
                  value={name}
                  onChange={this.handleChange}
                  error={createMode ? errors.name : errs.name}
                />
                <TextField
                  type="area"
                  name="description"
                  label="Category description"
                  placeholder="Category description"
                  value={description}
                  onChange={this.handleChange}
                  error={createMode ? errors.description : errs.description}
                />

                <ImageSelect
                  initialImage={headerImage}
                  label="Header image (en)"
                  name="headerImage"
                  type="Desktop"
                  handleSelect={this.handleSelectImage}
                  error={createMode ? errors.headerImage : errs.headerImage}
                />
                <ImageSelect
                  initialImage={headerImageMobile}
                  label="Header image - Mobile (en)"
                  name="headerImageMobile"
                  type="Mobile"
                  handleSelect={this.handleSelectImage}
                  error={
                    createMode
                      ? errors.headerImageMobile
                      : errs.headerImageMobile
                  }
                />
                <ImageSelect
                  initialImage={thumbnail}
                  label="Thumbnail (en)"
                  name="thumbnail"
                  type="Thumbnail"
                  handleSelect={this.handleSelectImage}
                  error={createMode ? errors.thumbnail : errs.thumbnail}
                />
              </div>

              <div className={style.col}>
                <TextField
                  name="name_fr"
                  label="Category name (French)"
                  placeholder="Category name (French)"
                  value={name_fr}
                  onChange={this.handleChange}
                  error={createMode ? errors.name : errs.name}
                />
                <TextField
                  type="area"
                  name="description_fr"
                  label="Category description (French)"
                  placeholder="Category description (French)"
                  value={description_fr}
                  onChange={this.handleChange}
                  error={
                    createMode ? errors.description_fr : errs.description_fr
                  }
                />
                <ImageSelect
                  initialImage={headerImage_fr}
                  label="Header image (fr)"
                  name="headerImage_fr"
                  type="Desktop"
                  handleSelect={this.handleSelectImage}
                  handleRemove={this.handleRemoveImage}
                  clearable
                />
                <ImageSelect
                  initialImage={headerImageMobile_fr}
                  label="Header image - Mobile (fr)"
                  name="headerImageMobile_fr"
                  type="Mobile"
                  handleSelect={this.handleSelectImage}
                  handleRemove={this.handleRemoveImage}
                  clearable
                />
                <ImageSelect
                  initialImage={thumbnail_fr}
                  label="Thumbnail (fr)"
                  name="thumbnail_fr"
                  type="Thumbnail"
                  handleSelect={this.handleSelectImage}
                  handleRemove={this.handleRemoveImage}
                  clearable
                />
              </div>
            </div>
          </div>
          <DialogActions>
            <Button
              disabled={createMode}
              onClick={this.openConfirmDialog}
              color="secondary"
            >
              Delete
            </Button>
            <Button onClick={(e) => this.handleSubmit(e)} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
        >
          <DialogTitle id="alert-dialog-title">{`Delete ${name}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete '${name}'?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeConfirmDialog} color="primary">
              cancel
            </Button>
            <Button
              disabled={createMode}
              onClick={(e) => this.handleDelete(e)}
              color="secondary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentWillUnmount() {
    this.setState({});
  }
}

Category.propTypes = {
  category: PropTypes.object,
  getCategory: PropTypes.func.isRequired,
  sub: PropTypes.bool,
  errors: PropTypes.object.isRequired,
};

Category.defaultProps = {
  category: {},
  parentId: null,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

const mapDispatchToProps = {
  getCategories,
  deleteCategory,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Category));
