import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from '../../utils';
import Pagination from '../Layout/Pagination';
import { useHistory } from 'react-router-dom';
import Select from '../Inputs/Select';
import AsyncSelect from '../Inputs/AsyncSelect';
import DatePicker from '../Inputs/DatePicker';
import moment from 'moment';
import Spinner from '../Inputs/Spinner';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import instance from 'src/http';

const TableRow = ({ className, data, handleViewMore }) => {
  const {
    activity,
    date,
    entity,
    entityId,
    newValue,
    oldValue,
    url,
    user,
    ip,
  } = data;

  const strippedUrl = url
    .replace(/^.*\/\/[^\/]+/, '')
    .replace('/dashboard', '');

  return (
    <div className={className}>
      <CellRow>{activity}</CellRow>

      <CellRow>
        <span>{strippedUrl}</span>
        <Tooltip onClick={() => handleViewMore(strippedUrl)}>more</Tooltip>
      </CellRow>
      <CellRow>{entity}</CellRow>
      <CellRow>{entityId}</CellRow>

      <CellRow width="20%">
        {oldValue ? <pre>{JSON.stringify(oldValue, null, 1)}</pre> : null}
        {oldValue ? (
          <Tooltip
            onClick={() => handleViewMore(JSON.stringify(oldValue, null, 1))}
          >
            more
          </Tooltip>
        ) : null}
      </CellRow>

      <CellRow width="20%">
        {newValue ? <pre>{JSON.stringify(newValue, null, 1)}</pre> : null}
        {newValue ? (
          <Tooltip
            onClick={() => handleViewMore(JSON.stringify(newValue, null, 1))}
          >
            more
          </Tooltip>
        ) : null}
      </CellRow>

      <CellRow>
        {moment(date).format('YYYY-MM-DD')} at {moment(date).format('HH:mm')}
      </CellRow>

      <CellRow>
        <span>{user.email}</span>

        <Tooltip onClick={() => handleViewMore(user.email)}>more</Tooltip>
      </CellRow>
      <CellRow>
        <span>{ip}</span>
        <Tooltip onClick={() => handleViewMore(ip)}>more</Tooltip>
      </CellRow>
    </div>
  );
};

function Auditlog({ match }) {
  const [entityOptions, setEntityOptions] = useState({});
  const [sortBy, setSortBy] = useState({ field: 'date', asc: false });
  const [activityFilter, setActivityFilter] = useState(null);
  const [entityFilter, setEntityFilter] = useState(null);
  const [userFilter, setUserFilter] = useState(null);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);
  const [auditData, setAuditData] = useState({});
  const [page, setPage] = useState(1);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');

  const handleViewMore = (data) => {
    setDialogData(data);
    setDialogOpen(true);
  };

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchEntityOptions();
    if (match.params.page) {
      fetchData(match.params.page);
      setPage(match.params.page);
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    fetchData();
    setPage(1);
  }, [
    activityFilter,
    entityFilter,
    userFilter,
    fromDateFilter,
    toDateFilter,
    JSON.stringify(sortBy),
  ]);

  const fetchData = async (page = 1, csv = false) => {
    const url = '/reports/audit';
    const options = {
      page: page,
      activity: activityFilter && activityFilter.value,
      entity: entityFilter && entityFilter.value,
      user: userFilter && userFilter.value,
      startDate: moment(fromDateFilter).startOf('day').toISOString(),
      endDate: moment(toDateFilter).endOf('day').toISOString(),
      csv: csv,
      order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
    };
    if (page === 1) history.push(`/dashboard/audit/1`);
    setErrors({});
    setLoading(true);
    try {
      const res = await instance.get(url, { params: options });

      if (csv) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `submissions-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        setAuditData(res.data);
      }
      setLoading(false);
    } catch (error) {
      setErrors(error.response.data);
      setLoading(false);
    }
  };

  const fetchEntityOptions = async () => {
    const url = '/reports/audit/options';

    try {
      const res = await instance.get(url);
      setEntityOptions(res.data.map((el) => ({ label: el, value: el })));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUsers = async (inputValue) => {
    const url = '/users';
    const options = {
      dropdown: true,
      search: inputValue,
    };

    try {
      const res = await instance.get(url, { params: options });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const renderTable = () => {
    if (!isEmpty(auditData))
      return auditData.rows.map((el) => (
        <StyledTableRow key={el.id} data={el} handleViewMore={handleViewMore} />
      ));
  };

  const changePage = (page) => {
    fetchData(page);
    setPage(page);
    history.push(`/dashboard/audit/${page}`);
  };

  const { count, rows } = auditData;
  const activityOptions = [
    { value: 'Page Visit', label: 'Page Visit' },
    { value: 'Record Viewed', label: 'Record Viewed' },
    { value: 'Record Updated', label: 'Record Updated' },
    { value: 'Record Deleted', label: 'Record Deleted' },
    { value: 'Record Created', label: 'Record Created' },
  ];

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  const sortIcon = sortBy.asc ? (
    <i className="fas fa-sort-up" style={{ marginLeft: '5px' }}></i>
  ) : (
    <i class="fas fa-sort-down" style={{ marginLeft: '5px' }}></i>
  );

  return (
    <Container>
      <h1>Audit Log</h1>

      <ControlsContainer>
        <Select
          name="entity"
          options={entityOptions}
          label="Entity"
          value={entityFilter}
          onChange={(value, valueType) => setEntityFilter(value)}
        />
        <Select
          name="activity"
          options={activityOptions}
          label="Activity"
          value={activityFilter}
          onChange={(value, valueType) => setActivityFilter(value)}
        />
        <AsyncSelect
          name="userId"
          label="User"
          loadOptions={fetchUsers}
          value={userFilter}
          onChange={(value, valueType) => setUserFilter(value)}
        />
        <DatePicker
          name="fromDate"
          selected={fromDateFilter}
          label="From Date"
          onChange={(date) => setFromDateFilter(date)}
          isClearable
        />
        <DatePicker
          name="toDate"
          selected={toDateFilter}
          label="To Date"
          onChange={(date) => setToDateFilter(date)}
          isClearable
        />
      </ControlsContainer>
      <Button onClick={() => fetchData(1, true)}>Export</Button>
      <TableHeader>
        <CellRow
          bold={sortBy.field === 'activity' ? true : false}
          header
          sortable
          onClick={() => setSort('activity')}
        >
          Activity {sortBy.field === 'activity' ? sortIcon : null}
        </CellRow>
        <CellRow header>Request Url</CellRow>
        <CellRow
          bold={sortBy.field === 'entity' ? true : false}
          header
          sortable
          onClick={() => setSort('entity')}
        >
          Entity {sortBy.field === 'entity' ? sortIcon : null}
        </CellRow>
        <CellRow
          bold={sortBy.field === 'entityId' ? true : false}
          header
          sortable
          onClick={() => setSort('entityId')}
        >
          Entity ID {sortBy.field === 'entityId' ? sortIcon : null}
        </CellRow>
        <CellRow width="20%" header>
          Old Value
        </CellRow>
        <CellRow width="20%" header>
          New Value
        </CellRow>
        <CellRow
          bold={sortBy.field === 'date' ? true : false}
          header
          sortable
          onClick={() => setSort('date')}
        >
          Date {sortBy.field === 'date' ? sortIcon : null}
        </CellRow>
        <CellRow
          bold={sortBy.field === 'user' ? true : false}
          header
          sortable
          onClick={() => setSort('user')}
        >
          User {sortBy.field === 'user' ? sortIcon : null}
        </CellRow>
        <CellRow header>IP Address</CellRow>
      </TableHeader>

      <TableContainer>
        {loading ? (
          <Spinner />
        ) : isEmpty(errors) ? (
          renderTable()
        ) : (
          errors.message
        )}
      </TableContainer>

      {count && isEmpty(errors) ? (
        <Pagination
          totalRecords={count}
          page={page}
          onPageChange={changePage}
        />
      ) : null}

      <Dialog
        maxWidth="lg"
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
      >
        <DialogTitle onClose={() => setDialogOpen(false)}>Details</DialogTitle>
        <DialogContent dividers>
          {dialogData.charAt(0) === '{' ? (
            <pre>{dialogData}</pre>
          ) : (
            <span>{dialogData}</span>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

Auditlog.propTypes = {};

export default Auditlog;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  color: blue;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledTableRow = styled(TableRow)`
  display: flex;
  width: 100%;
  position: relative;
  min-height: 150px;
  height: 150px;
  overflow: hidden;

  &:hover {
    background: rgba(247, 247, 250, 0.6);
  }
`;

const CellRow = styled.div`
  position: relative;
  display: ${(p) => (p.overflow ? 'block' : 'flex')};
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 5px 10px;
  width: ${(p) => (p.width ? p.width : '10%')};

  overflow: ${(p) => (p.overflow ? 'auto' : 'hidden')};
  resize: ${(p) => (p.resize ? p.resize : 'none')};
  font-weight: ${(p) => (p.bold ? '600' : 'normal')};
  word-break: ${(p) => (p.break ? 'break-all' : 'normal')};
  ${(p) => (p.header && p.sortable ? 'cursor: pointer' : null)};
  &:hover {
    ${(p) =>
      p.header && p.sortable ? 'background: rgba(240,240,240,0.9)' : null}

    ${Tooltip} {
      display: block;
    }
  }

  span {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 10px;
    padding-bottom: 5px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  pre {
    width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  min-height: 480px;
`;

const ControlsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 30px 0;

  .input-cntr {
    margin: 10px;
    width: 15%;
  }
`;

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  padding: 12px 0 0 0;
  /* border-bottom: 2px solid #efefef; */
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  background: rgba(247, 247, 250, 0.6);
`;

const TableContainer = styled.div`
  width: 100%;
`;
