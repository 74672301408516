import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { getCategories } from '../../../redux/actions/categoryActions';
import CreatableSelect from '../../Inputs/CreatableSelect';
import TextField from '../../Inputs/TextField';
import Wysiwyg from '../../Inputs/Wysiwyg/Wysiwyg';
import DatePicker from '../../Inputs/DatePicker';
import Select from '../../Inputs/Select';
import Button from '../../Inputs/Button';
import LanguageSwitcher from '../../Layout/LanguageSwitcher';
import { a11yProps, isEmpty } from '../../../utils';
import { withRouter } from 'react-router-dom';
import ImageSelect from '../../Inputs/ImageSelect';
import Spinner from '../../Inputs/Spinner';
import css from './Discounts.module.scss';
import { Alert, Tab, Tabs } from '@mui/material';
import TabPanel from 'src/components/Layout/TabPanel';
import SEOFields from '../common/SEOFields';
import instance from 'src/http';

const initialSelect = {
  label: 'Product image',
  name: 'Product Image',
  type: 'Product',
};

const initialState = {
  name: '',
  content: '',
  affiliateLink: '',
  thumbnail: null,
  thumbnail_fr: null,
  headerImage: null,
  headerImage_fr: null,
  headerImageMobile: null,
  headerImageMobile_fr: null,
  productImages: [],
  imageSelects: [initialSelect],
  excerpt: '',
  startDate: null,
  endDate: null,
  category: null,
  categoryId: '',
  business: '',
  businessId: '',
  businesses: [],
  retailer: '',
  categories: [],
  subcategories: [],
  file: null,
  errors: {},
  tab: 'en',
  en: {
    name: '',
    content: '',
    excerpt: '',
    tags: [],
  },
  fr: {
    name: '',
    content: '',
    excerpt: '',
    tags: [],
  },
  loading: true,
  selectedTab: 0,
  metaDescription: '',
  twitterSummary: '',
  openGraphTitle: '',
  openGraphType: '',
  openGraphUrl: '',
  openGraphDescription: '',
  fetchErrors: null,
};

export class Discount extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = initialState;
    this.handleRemoveProductImage = this.handleRemoveProductImage.bind(this);
    this.handleSelectProductImage = this.handleSelectProductImage.bind(this);
    this.handleSelectNewProductImage =
      this.handleSelectNewProductImage.bind(this);
  }

  async componentDidMount() {
    const { mode, id } = this.props.match.params;

    try {
      await Promise.all([
        this.getCategories(),
        this.getBusinesses(),
        this.getTagsEn(),
        this.getTagsFr(),
      ]);

      if (mode === 'edit' && id) {
        const offerData = await this.getOfferById(id);
        const selectedBusiness = await this.getBusinessById(
          offerData.businessId
        );
        this.setState({ business: selectedBusiness });
        this.updateState(offerData);
      }

      this.setState({ loading: false });
    } catch (err) {
      this.setState({ fetchErrors: err, loading: false });
    }

    if (mode === 'list' && id) {
      const business = await this.getBusinessById(id);
      this.setState({ business });
    }
  }

  getOfferById = async (offerId) => {
    try {
      const res = await instance.get(`/offers/${offerId}`);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  getBusinessById = async (businessId) => {
    try {
      const res = await instance.get(`/businesses/${businessId}`);
      return { label: res.data.name, value: res.data.id };
    } catch (err) {
      throw err;
    }
  };

  getCategories = async () => {
    try {
      const res = await instance.get('/categories', {
        params: { dropdown: true },
      });

      this.setState({
        categories: res.data.map((el) => {
          return {
            label: el.name,
            options: el.subcategories.map((el) => {
              return { label: el.name, value: el.id };
            }),
          };
        }),
      });
    } catch (err) {
      throw err;
    }
  };

  getBusinesses = async () => {
    try {
      const res = await instance.get('/businesses', {
        params: { dropdown: true },
      });

      this.setState({
        businesses: res.data.results.map((el) => ({
          label: el.name,
          value: el.id,
        })),
      });
    } catch (err) {
      throw err;
    }
  };

  getTagsEn = async () => {
    try {
      const res = await instance.get('/tags', {
        params: { dropdown: true },
      });

      this.setState({
        tagOptions: res.data.map((el) => ({
          value: el,
          label: el,
        })),
      });
    } catch (err) {
      throw err;
    }
  };

  getTagsFr = async () => {
    try {
      const resFR = await instance.get('/tagsfr', {
        params: { dropdown: true },
      });
      this.setState({
        tagOptionsFR: resFR.data.map((el) => ({
          value: el,
          label: el,
        })),
      });
    } catch (err) {
      throw err;
    }
  };

  updateState = (discount) => {
    this.setState({
      en: {
        name: discount.name,
        content: discount.content,
        excerpt: discount.excerpt,
        tags: discount.tags.map((el) => ({ label: el, value: el })),
      },
      fr: {
        name: discount.name_fr,
        content: discount.content_fr,
        excerpt: discount.excerpt_fr,
        tags: discount.tagsfr.map((el) => ({ label: el, value: el })),
      },
      content: discount.content,
      businessId: discount.businessId,
      affiliateLink: discount.affiliateLink,
      excerpt: discount.excerpt,
      startDate: discount.startDate
        ? moment(discount.startDate).startOf('day').toDate()
        : null,
      endDate: discount.endDate
        ? moment(discount.endDate).endOf('day').toDate()
        : null,
      subcategories: isEmpty(discount.subcategories)
        ? []
        : discount.subcategories.map((el) => {
            return { value: el.id, label: el.name };
          }),
      category: discount.subcategory
        ? {
            label: discount.subcategory.name,
            value: discount.subcategory.id,
          }
        : null,
      retailer: discount.retailer,
      headerImage: discount.headerImage,
      headerImageMobile: discount.headerImageMobile,
      headerImage_fr: discount.headerImage_fr,
      headerImageMobile_fr: discount.headerImageMobile_fr,
      productImages: discount.productImages,
      thumbnail: discount.thumbnail,
      thumbnail_fr: discount.thumbnail_fr,
      loading: false,
    });
  };

  changeTabs = (tab) => {
    this.setState({ tab: tab });
  };

  onChange = (e) => {
    const { tab } = this.state;
    this.setState({
      [tab]: { ...this.state[tab], [e.target.name]: e.target.value },
    });
  };

  handleWysiwyg = (content) => {
    const { tab } = this.state;
    this.setState({
      [tab]: {
        ...this.state[tab],
        content: content,
      },
    });
  };

  handleTextField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleStartDate = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDate = (endDate) => {
    const { startDate } = this.state;
    this.setState({ endDate });

    if (moment(startDate).isAfter(endDate)) {
      this.setState({ startDate: endDate });
    }
  };

  handleSelectCategory = (subcategories) => {
    this.setState({ subcategories });
  };

  handleSelectBusiness = (business) => {
    this.setState({ business: business });
  };

  handleFileChange = (file, name) => {
    this.setState({ [name]: file });
  };

  handleSelectImage = (image, name) => {
    this.setState({ [name]: image });
  };

  handleSelectProductImage = (image, i) => {
    if (!isEmpty(image)) {
      this.setState({
        productImages: [
          ...this.state.productImages.slice(0, i),
          image,
          ...this.state.productImages.slice(i + 1),
        ],
      });
    }
  };

  handleSelectNewProductImage = (image) => {
    if (!isEmpty(image)) {
      this.setState({
        productImages: [...this.state.productImages, image],
        imageSelects: [],
      });
    }
  };

  handleAddProductImages = () => {
    this.setState({
      imageSelects: [...this.state.imageSelects, initialSelect],
    });
  };

  handleRemoveProductImage(image) {
    if (!isEmpty(image)) {
      const newProductImages = this.state.productImages.filter(
        (img) => img !== image
      );
      this.setState({
        productImages: newProductImages,
      });
    }
  }

  handleSave = async () => {
    const {
      affiliateLink,
      startDate,
      endDate,
      subcategories,
      retailer,
      business,
      en,
      fr,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      productImages,
      thumbnail,
      thumbnail_fr,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      openGraphDescription,
    } = this.state;

    const { mode, id, bizId } = this.props.match.params;

    const payload = {
      name: en.name,
      content: en.content,
      affiliateLink,
      excerpt: en.excerpt,
      startDate: moment(startDate).startOf('day'),
      endDate: moment(endDate).endOf('day'),
      subcategories: isEmpty(subcategories)
        ? undefined
        : subcategories.map((el) => el.value),
      businessId: business ? parseInt(business.value) : null,
      retailer,
      tags: en.tags ? en.tags.map((el) => el.label) : [],
      tagsfr: fr.tags ? fr.tags.map((el) => el.label) : [],
      name_fr: fr.name,
      content_fr: fr.content,
      excerpt_fr: fr.excerpt,
      headerImage: !isEmpty(headerImage) ? headerImage.id : null,
      headerImageMobile: !isEmpty(headerImageMobile)
        ? headerImageMobile.id
        : null,
      headerImage_fr: !isEmpty(headerImage_fr) ? headerImage_fr.id : null,
      headerImageMobile_fr: !isEmpty(headerImageMobile_fr)
        ? headerImageMobile_fr.id
        : null,
      productImages:
        productImages.length > 0 ? productImages.map((obj) => obj.id) : null,
      thumbnail: !isEmpty(thumbnail) ? thumbnail.id : null,
      thumbnail_fr: !isEmpty(thumbnail_fr) ? thumbnail_fr.id : null,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      openGraphDescription,
    };
    const { history } = this.props;
    const requestMethod = mode === 'edit' ? 'put' : 'post';
    const path = mode === 'edit' ? `/offers/${id}` : '/offers';

    try {
      await instance[requestMethod](path, payload);
      history.push('/dashboard/discounts');
      if (bizId) history.push(`/dashboard/businesses/${bizId}/edit/1`);
      if (mode === 'list' && id)
        history.push(`/dashboard/businesses/${id}/edit/1`);
    } catch (error) {
      const obj = error.response.data;
      if (
        obj.hasOwnProperty('name_fr') ||
        obj.hasOwnProperty('content_fr') ||
        obj.hasOwnProperty('excerpt_fr')
      ) {
        this.setState({ tab: 'fr' });
      } else this.setState({ tab: 'en' });
      this.setState({ errors: error.response.data, fileUploading: false });
      this.myRef.current.scrollIntoView();
    }
  };

  handleFileUpload = (imgType, file) => {
    this.setState({ [imgType]: file });
  };

  handleCreatable = (tags) => {
    const { tab } = this.state;
    this.setState({ ...this.state, [tab]: { ...this.state[tab], tags } });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      affiliateLink,
      startDate,
      endDate,
      subcategories,
      business,
      businesses,
      categories,
      errors,
      tab,
      en,
      fr,
      thumbnail,
      thumbnail_fr,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      productImages,
      imageSelects,
      deleteMode,
      loading,
      selectedTab,
      fetchErrors,
    } = this.state;

    const { history } = this.props;

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center',
    };

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    const { mode, id } = this.props.match.params;

    const handleDelete = async () => {
      try {
        await instance.delete(`/offers/${id}`);
        history.push('/dashboard/discounts');
      } catch (error) {
        this.setState({ errors: error.response.data });
      }
    };

    const handleDeleteBtn = () => {
      if (this.state.deleteMode) {
        return (
          <div style={{ display: 'flex' }}>
            <Button
              styles={['btn', 'btn_red']}
              text="Confirm"
              onClick={handleDelete}
            />
            <Button
              text="Cancel"
              onClick={() => this.setState({ deleteMode: false })}
            />
          </div>
        );
      }
    };

    let nameErr;
    let excerptErr;
    let contentErr;
    let tagErr;

    if (!isEmpty(errors)) {
      nameErr = tab === 'fr' ? errors.name_fr : errors.name;
      excerptErr = tab === 'fr' ? errors.excerpt_fr : errors.excerpt;
      contentErr = tab === 'fr' ? errors.content_fr : errors.content;
      tagErr = tab === 'fr' ? errors.tagsfr : errors.tags;
    }

    let disableSelect = false;
    if (mode === 'list' && id) disableSelect = true;

    if (fetchErrors) {
      const errMessage =
        fetchErrors.response?.data.message || 'Oops, something went wrong.';
      return <Alert severity={'error'}>{errMessage}</Alert>;
    }
    console.log(categories, 'categories');
    if (!loading)
      return (
        <div className={css.discountsContainer}>
          <h1>
            {mode === 'add' ? 'New discount' : tab === 'en' ? en.name : fr.name}
          </h1>
          <LanguageSwitcher language={tab} changeLanguage={this.changeTabs} />

          <Tabs value={selectedTab} onChange={this.handleChangeTab}>
            <Tab label="General information" {...a11yProps(0)} />
            <Tab label="Seo" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <TextField
              value={tab === 'en' ? en.name : fr.name}
              name="name"
              label="Discount name"
              placeholder="Discount name"
              required
              className="input-lg"
              onChange={this.onChange}
              error={nameErr}
            />
            <Select
              value={subcategories}
              name="subcategories"
              label="Category"
              options={categories}
              formatGroupLabel={formatGroupLabel}
              className="input-lg"
              onChange={this.handleSelectCategory}
              error={errors.subcategories}
              clearable={false}
              isMulti
            />
            <Select
              required
              value={business}
              name="Business name"
              label="Business name"
              options={businesses}
              onChange={this.handleSelectBusiness}
              className="input-lg"
              clearable={false}
              disabled={disableSelect}
              error={errors.businessId}
            />
            <DatePicker
              name="endDate"
              selected={endDate}
              label="End date"
              onChange={this.handleEndDate}
              error={errors.endDate}
              minDate={new Date()}
              isClearable={true}
            />
            <DatePicker
              name="startDate"
              selected={startDate}
              label="Start date"
              onChange={this.handleStartDate}
              error={errors.startDate}
              maxDate={endDate}
              isClearable={true}
            />
            <TextField
              value={tab === 'en' ? en.excerpt : fr.excerpt}
              name="excerpt"
              label="Excerpt"
              placeholder="Short description"
              className="input-lg"
              onChange={this.onChange}
              error={excerptErr}
            />
            <TextField
              value={affiliateLink}
              name="affiliateLink"
              label="Affiliate Link"
              placeholder="Affiliate Link"
              required
              className="input-lg"
              onChange={this.handleTextField}
              error={errors.affiliateLink}
            />
            <Wysiwyg
              name="content"
              label="Page content"
              val={tab === 'en' ? en.content : fr.content}
              onChange={this.handleWysiwyg}
              error={contentErr}
            />
            <CreatableSelect
              value={tab === 'en' ? en.tags : fr.tags}
              name="tags"
              label={`Tags (${tab})`}
              className="input-lg"
              isMulti
              onChange={this.handleCreatable}
              options={
                tab === 'en' ? this.state.tagOptions : this.state.tagOptionsFR
              }
              error={tagErr}
            />
            <ImageSelect
              initialImage={headerImage}
              label="Header image (en)"
              name="headerImage"
              type="Desktop"
              handleSelect={this.handleSelectImage}
              error={errors.headerImage}
            />
            <ImageSelect
              initialImage={headerImage_fr}
              label="Header image (fr)"
              name="headerImage_fr"
              type="Desktop"
              handleSelect={this.handleSelectImage}
            />
            <ImageSelect
              initialImage={headerImageMobile}
              label="Header image - Mobile (en)"
              name="headerImageMobile"
              type="Mobile"
              handleSelect={this.handleSelectImage}
              error={errors.headerImageMobile}
            />
            <ImageSelect
              initialImage={headerImageMobile_fr}
              label="Header image - Mobile (fr)"
              name="headerImageMobile_fr"
              type="Mobile"
              handleSelect={this.handleSelectImage}
            />
            <ImageSelect
              initialImage={thumbnail}
              label="Thumbnail (en)"
              name="thumbnail"
              type="Thumbnail"
              handleSelect={this.handleSelectImage}
              error={errors.thumbnail}
            />
            <ImageSelect
              initialImage={thumbnail_fr}
              label="Thumbnail (fr)"
              name="thumbnail_fr"
              type="Thumbnail"
              error={errors.thumbnail_fr}
              handleSelect={this.handleSelectImage}
            />
            <p style={{ marginLeft: '12px' }}>Select product image(s):</p>
            <div
              style={{
                display: 'grid',
                gridGap: '1rem',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 0fr))',
              }}
            >
              {productImages &&
                productImages.map((productImage, i) => (
                  <div
                    key={productImage.label}
                    style={{ display: 'grid', placeItems: 'center' }}
                  >
                    <ImageSelect
                      initialImage={productImage}
                      name="productImage"
                      type="Product"
                      handleSelectProduct={(image) =>
                        this.handleSelectProductImage(image, i)
                      }
                      error={errors.productImage}
                      clearable={true}
                      handleRemove={(image) => {
                        this.handleRemoveProductImage(image);
                      }}
                    />
                  </div>
                ))}

              {imageSelects.map((obj) => (
                <div
                  key={obj.label}
                  style={{ display: 'grid', placeItems: 'center' }}
                >
                  <ImageSelect
                    label={obj.label}
                    name={obj.name}
                    type={obj.type}
                    handleSelectProduct={(image) =>
                      this.handleSelectNewProductImage(image)
                    }
                    error={errors.obj}
                    clearable={true}
                    handleRemove={(image) => {
                      this.handleRemoveProductImage(image);
                    }}
                  />
                </div>
              ))}
            </div>

            <Button
              text={'Add product image'}
              onClick={this.handleAddProductImages}
            />
            {errors.productImages ? (
              <p style={{ color: 'red', fontSize: '12px', margin: '10px 0' }}>
                {errors.productImages}
              </p>
            ) : null}
            <br />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <SEOFields
              value={this.state}
              errors={this.state.errors}
              handleChange={this.handleChange}
            />
          </TabPanel>
          <div className="btn_appart">
            <Button
              text={mode === 'edit' ? 'Save' : 'Create discount'}
              onClick={this.handleSave}
            />

            {mode === 'edit' ? (
              deleteMode ? (
                handleDeleteBtn()
              ) : (
                <Button
                  onClick={() => this.setState({ deleteMode: true })}
                  text="Delete"
                  styles={['btn', 'btn_red']}
                />
              )
            ) : null}
          </div>
          {!isEmpty(errors) ? (
            <Alert
              style={{ marginTop: 15 }}
              severity={'error'}
              ref={this.myRef}
            >
              There's an error, please check your input.
            </Alert>
          ) : null}
        </div>
      );

    if (loading) return <Spinner />;
  }
}

Discount.propTypes = {
  categories: PropTypes.array.isRequired,
  getCategories: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  errors: state.errors,
});

const mapDispatchToProps = {
  getCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Discount));
