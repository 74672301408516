import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({
  name,
  placeholder,
  className,
  value,
  label,
  error,
  onChange,
  helperText,
  disabled,
  minDate,
  maxDate,
  required,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  dateFormat,
  timeCaption,
  width,
  isClearable,
  startDate,
  selected,
}) => {
  return (
    <div
      style={{ width: width }}
      className={classnames('input-cntr', className)}
    >
      <label className="label" htmlFor={name}>
        {label}
        {required ? <span className="text-danger">*</span> : null}
      </label>
      <div style={{ marginTop: label ? '10px' : '0px' }} className="inner">
        <ReactDatePicker
          selected={selected}
          onChange={onChange}
          dateFormat={dateFormat}
          className={classnames('control', {
            'is-invalid': error,
            'is-disabled': disabled,
          })}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={timeIntervals}
          timeCaption={timeCaption}
          minDate={minDate}
          maxDate={maxDate}
          isClearable={isClearable}
          startDate={startDate}
          placeholder={placeholder}
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={30}
        />
        {error ? (
          <small className="invalid-feedback">
            <i className="fas fa-times" />
            {error}
          </small>
        ) : (
          helperText && (
            <small className="helper-text">
              <i className="fas fa-info-circle" />
              {helperText}
            </small>
          )
        )}
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  orientation: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  required: PropTypes.bool,
  dateFormat: PropTypes.string,
  showTimeSelect: PropTypes.string,
  timeIntervals: PropTypes.string,
  timeCaption: PropTypes.string,
  showTimeSelectOnly: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DatePicker.defaultProps = {
  width: '200px',
  autoComplete: 'off',
  orientation: 'vertical',
  required: false,
  dateFormat: 'MMMM d, yyyy',
  className: '',
  placeholder: '',
  value: null,
  error: null,
};

export default DatePicker;
