/* eslint-disable react/button-has-type */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Spinner from './Spinner';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';

import style from './Button.module.scss';

const Button = ({
  text,
  isLoading,
  className,
  disabled,
  type,
  onClick,
  btnLink,
  url,
  needConfirm,
  confirmationText,
  onConfirm,
  styles,
  small,
}) => {
  const [confirm, setconfirm] = useState(false);

  // Maps mapStyles into classnames
  const mapStyles = styles.map((el) => style[el]);

  if (isLoading) return <Spinner />;

  if (btnLink)
    return (
      <Link
        className={classnames(style.btn_link, {
          'is-disabled': disabled,
        })}
        to={url}
      >
        {text}
      </Link>
    );

  if (needConfirm) {
    return (
      <Fragment>
        <button
          type={type}
          onClick={needConfirm ? () => setconfirm(true) : onClick}
          disabled={disabled}
          className={classnames(mapStyles, className, {
            'is-disabled': disabled,
          })}
        >
          {text}
        </button>
        <Dialog open={confirm} maxWidth="lg" disableBackdropClick>
          <DialogTitle>
            <div className={style.dialog_header}>
              {confirmationText && <h3>{confirmationText}</h3>}
              {!confirmationText && <h3>Confirm deletion?</h3>}
              <i
                onClick={() => setconfirm(false)}
                className="fas fa-times closeDialog"
              ></i>
            </div>
          </DialogTitle>
          <DialogActions>
            <button
              type={type}
              onClick={(...args) => {
                setconfirm(false);
                onClick(...args);
              }}
              disabled={disabled}
              className={classnames(
                `${style.btn} ${style.btn_sm} ${style.btn_red}`,
                {
                  'is-disabled': disabled,
                }
              )}
            >
              Confirm
            </button>
            <button
              type={type}
              onClick={() => setconfirm(false)}
              disabled={disabled}
              className={classnames(`${style.btn} ${style.btn_sm}`, {
                'is-disabled': disabled,
              })}
            >
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classnames(mapStyles, className, {
        'is-disabled': disabled,
        [style.btn_sm]: small,
      })}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  btnLink: PropTypes.bool,
  url: PropTypes.string,
  needConfirm: PropTypes.bool,
  confirmationText: PropTypes.string,
  onConfirm: PropTypes.func,
  styles: PropTypes.array,
  small: PropTypes.bool,
};

Button.defaultProps = {
  isLoading: false,
  disabled: false,
  styles: ['btn'],
  onClick: undefined,
  className: null,
  btnLink: false,
  url: '',
  needConfirm: false,
  onConfirm: undefined,
};

export default Button;
