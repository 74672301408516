import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import css from './MediaLibrary.module.scss';
import ImageThumbnail from './ImageThumbnail';
import {
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from '@mui/material';

import MediaLibraryEntry from './MediaLibraryEntry';
import { isEmpty } from '../../../utils';
import Spinner from '../../Inputs/Spinner';
import Select from '../../Inputs/Select';
import instance from 'src/http';

export default function MediaLibrary({ mediaType, grabSelectedImage }) {
  const [loading, setloading] = useState(true);
  const [debounce, setdebounce] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setselectedImage] = useState(null);
  const [page, setpage] = useState(1);
  const [images, setimages] = useState([]);
  const [totalResults, settotalResults] = useState(1);
  const [errors, seterrors] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [filter, setFilter] = useState({ label: 'All', value: undefined });

  const grid = useRef(null);

  useEffect(() => {
    if (debounce) {
      setTimeout(() => {
        setdebounce(false);
        seterrors({});
      }, 10000);
    }
    return () => {
      clearTimeout();
    };
  }, [debounce]);

  useEffect(() => {
    getImages(1);
    setpage(1);
  }, [filter.value]);

  const getImages = async (page = 1) => {
    const path = '/media';
    let limit = 20;
    const params = {
      params: {
        page,
        limit,
        type: mediaType ? mediaType : filter.value,
        label: searchText,
      },
    };
    // if (errors.status !== 404) {
    setloading(true);
    seterrors({});
    try {
      const res = await instance.get(path, params);
      setloading(false);
      settotalResults(res.data.totalResults);
      setimages(
        page === 1 ? res.data.results : images.concat(res.data.results)
      );
      setpage(page + 1);
    } catch (err) {
      if (page === 1) {
        setimages([]);
      }
      seterrors(err.response);
      setdebounce(true);
      setloading(false);
    }
    // }
  };

  useEffect(() => {
    if (grid.current.scrollHeight <= grid.current.offsetHeight) {
      getImages(page);
    }
  }, [page]);

  useEffect(() => {
    if (dialogOpen === false) setselectedImage(null);
  }, [dialogOpen]);

  const selectImage = (img) => {
    setselectedImage(img);
    if (grabSelectedImage) {
      setConfirmDialogOpen(true);
    } else {
      setDialogOpen(true);
    }
  };

  const confirmSelection = () => {
    grabSelectedImage(selectedImage);
    setConfirmDialogOpen(false);
  };

  const renderThumbnails = () => {
    return images.map((el) => {
      return <ImageThumbnail key={el.id} onClick={selectImage} image={el} />;
    });
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleScroll = (e) => {
    if (
      e.target.scrollTop + e.target.offsetHeight + 50 >=
      e.target.scrollHeight
    ) {
      getImages(page);
    }
  };

  const handleNew = () => {
    setselectedImage({});
    setDialogOpen(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getImages(1);
    setpage(1);
  };

  const filterOptions = [
    { label: 'Desktop Header', value: 'Desktop' },
    { label: 'Mobile Header', value: 'Mobile' },
    { label: 'Page Header', value: 'Page Header' },
    { label: 'Thumbnail', value: 'Thumbnail' },
    { label: 'Product', value: 'Product' },
    { label: 'All', value: undefined },
  ];

  return (
    <div className={css.wrapper}>
      <h1>Media Library {mediaType ? `- ${mediaType}` : null}</h1>

      <div className={css.controls_container}>
        <Button color="primary" onClick={handleNew}>
          New Entry
        </Button>
        <Select
          name="filter"
          value={filter}
          onChange={setFilter}
          options={filterOptions}
          disabled={!!mediaType}
          clearable={false}
        />
        <form onSubmit={handleSearch}>
          <TextField
            name="searchText"
            value={searchText}
            onChange={handleSearchChange}
            className={css.input_sm}
            placeholder="Search Images"
            size="small"
            inputProps={{ maxLength: 30 }}
          />
          <Button variant="outlined" size="large" type="submit">
            Search
          </Button>
        </form>
      </div>

      {images.length || loading ? null : (
        <Alert severity="error">No items found</Alert>
      )}

      <div
        onScroll={(e) => handleScroll(e)}
        id="grid"
        className={css.container}
        ref={grid}
      >
        {renderThumbnails()}
        {loading ? <Spinner /> : null}
      </div>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        disableBackdropClick
      >
        <MediaLibraryEntry
          image={selectedImage}
          closeDialog={closeDialog}
          getData={getImages}
        />
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">Use this image?</DialogTitle>
        <DialogContent>You are about to select an image</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="secondary">
            Cancel
          </Button>

          <Button onClick={confirmSelection} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

MediaLibrary.propTypes = {
  mediaType: PropTypes.string,
  grabSelectedImage: PropTypes.func,
};

MediaLibrary.defaultProps = {
  mediaType: undefined,
  grabSelectedImage: undefined,
};
