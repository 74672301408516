/* eslint-disable import/prefer-default-export */
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GET_ERRORS,
  SET_LOADING,
  LOGOUT,
} from '../types';
import setAuthToken from '../../components/auth/setAuthToken';
import instance from 'src/http';

export const setLoading =
  (val = true) =>
  async (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: val,
    });
  };

export const loadUser = () => async (dispatch) => {
  setAuthToken();
  try {
    const res = await instance.get('/users/profile');
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  dispatch(setLoading());
  const body = { email, password };
  try {
    const res = await instance.post('/users/cmslogin', body);
    setAuthToken(localStorage.token);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const updateProfile = (body) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const res = await instance.post('/users/profile', body);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
