import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from '../../../../utils';
import Wrapper from './Wrapper';
import _, { drop, isEqual, update } from 'lodash';
import css from './Question.module.scss';

// Question components
import Text from './Text';
import Select from '../../../Inputs/Select';
import Button from '../../../Inputs/Button';
import Wysiwyg from '../../../Inputs/Wysiwyg/Wysiwyg';
import TextArea from './TextArea';
import Dropdown from './Dropdown';
import instance from 'src/http';

const initialState = {
  text: '',
  text_fr: '',
  type: null,
  isRequired: false,
  questionData: {},
  createMode: true,
  options: [],
  id: '',
  orderIndex: undefined,
  maxResponseLength: undefined,
  errors: {},
};

export class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      text_fr: '',
      type: null,
      isRequired: false,
      questionData: {},
      createMode: true,
      options: [],
      errors: {},
    };
  }

  static propTypes = {
    question: PropTypes.object.isRequired,
    questions: PropTypes.array.isRequired,
    saveQuestion: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    getPoll: PropTypes.func.isRequired,
  };

  updateState = (data) => {
    this.setState({
      ...data,
      text: data.text,
      text_fr: data.text_fr,
      type: { label: data.type, value: data.type.toLowerCase() },
      isRequired: data.required,
    });
  };

  componentDidMount() {
    const { question } = this.props;
    if (!isEmpty(question)) {
      this.updateState(question);
      this.setState({ createMode: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { question } = this.props;
    if (!isEqual(question, prevProps.question) && !isEmpty(question)) {
      this.setState({ createMode: false });
      this.updateState(question);
    }
  }

  setQuestionData = (data) => {
    this.setState({ questionData: data });
  };

  renderQuestionType = () => {
    const { type, maxResponseLength, validator, options, id, errors } =
      this.state;
    if (!isEmpty(type)) {
      switch (type.value) {
        case 'textbox':
          const textboxData = {
            maxResponseLength,
            validator,
          };
          return (
            <Text
              key={id}
              grabQuestionData={this.setQuestionData}
              data={textboxData}
            />
          );
        case 'textarea':
          const textareaData = {
            maxResponseLength,
          };
          return (
            <TextArea
              key={id}
              grabQuestionData={this.setQuestionData}
              data={textareaData}
            />
          );
        case 'dropdown':
          const dropdownData = [...options];
          return (
            <Dropdown
              key={id}
              grabQuestionData={this.setQuestionData}
              data={dropdownData}
              errors={!isEmpty(errors) ? errors.data.options : null}
            />
          );
        case 'checkbox':
          return null;

        case 'multiselect':
          const multiselectData = [...options];
          return (
            <Dropdown
              key={id}
              grabQuestionData={this.setQuestionData}
              data={multiselectData}
              errors={!isEmpty(errors) ? errors.data.options : null}
            />
          );
        default:
          return null;
          break;
      }
    }
  };

  handleSave = async () => {
    const { match } = this.props;
    const {
      id,
      questions,
      text,
      text_fr,
      isRequired,
      type,
      questionData,
      createMode,
      orderIndex,
    } = this.state;

    const pollId = match.params.id;
    const questionId = id;
    const path = createMode
      ? `/polls/${pollId}/questions`
      : `/polls/${pollId}/questions/${questionId}`;
    const method = createMode ? 'post' : 'put';
    let payload = {
      orderIndex: orderIndex ? orderIndex : this.props.questions.length + 1,
      text: text,
      text_fr: text_fr,
      required: isRequired,
      type: !isEmpty(type) ? type.label : undefined,
      ...questionData,
    };
    try {
      const res = await instance[method](path, payload);
      this.props.getPoll();
      this.setState(initialState);
      this.props.pollQuestionHelper({});
    } catch (err) {
      this.setState({ errors: err.response });
    }
  };

  handleDelete = async () => {
    const { match } = this.props;
    const { id } = this.state;
    const pollId = match.params.id;
    const questionId = id;
    const path = `/polls/${pollId}/questions/${questionId}`;

    try {
      const res = await instance.delete(path);
      this.props.getPoll();
      this.setState(initialState);
      this.props.pollQuestionHelper({});
    } catch (err) {
      this.setState({ errors: err.response });
    }
  };

  handleCancel = () => {
    this.setState(initialState);
    this.props.pollQuestionHelper({});
  };
  handleText = (val) => {
    this.setState({ text: val });
  };
  handleTextFr = (val) => {
    this.setState({ text_fr: val });
  };
  handleSelect = (val) => {
    this.setState({ type: val });
  };
  handleCheckbox = (e) => {
    this.setState({ isRequired: e.target.checked });
  };

  render() {
    const types = [
      { label: 'Textbox', value: 'textbox' },
      { label: 'Textarea', value: 'textarea' },
      { label: 'Dropdown', value: 'dropdown' },
      { label: 'Checkbox', value: 'checkbox' },
      { label: 'Multiselect', value: 'multiselect' },
    ];

    const {
      questions,
      text,
      text_fr,
      isRequired,
      type,
      questionData,
      createMode,
      errors,
    } = this.state;

    return (
      <div style={{ padding: '10px' }}>
        <h2>{createMode ? 'Add question' : 'Edit question'}</h2>
        <Wrapper>
          <Wysiwyg
            name="text"
            label="Question text (en)"
            val={text}
            onChange={this.handleText}
            error={!isEmpty(errors) ? errors.data.text : null}
          />
        </Wrapper>
        <Wrapper>
          <Wysiwyg
            name="text_fr"
            label="Question text (fr)"
            val={text_fr}
            onChange={this.handleTextFr}
            error={!isEmpty(errors) ? errors.data.text_fr : null}
          />
        </Wrapper>
        <label htmlFor="isRequired" className={css.isRequired}>
          Required
          <input
            name="isRequired"
            type="checkbox"
            checked={isRequired}
            onChange={this.handleCheckbox}
          />
        </label>
        <hr />
        {!isEmpty(errors) ? (
          <span className={css.error}>{errors.data.message}</span>
        ) : null}
        <Select
          name="type"
          label="Question Type"
          value={type}
          required
          onChange={this.handleSelect}
          options={types}
          disabled={!createMode}
          clearable={false}
        />

        <div>{this.renderQuestionType()}</div>

        <div style={{ display: 'flex' }}>
          <Button onClick={this.handleSave} text="Save" />
          <Button onClick={this.handleCancel} text="Cancel" />
          <Button
            text="Delete"
            styles={['btn', 'btn_red']}
            onClick={this.handleDelete}
          />
        </div>
      </div>
    );
  }
}

Question.defaultProps = {
  question: {},
};

export default withRouter(Question);
