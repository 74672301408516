import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '../../Inputs/TextField';
import Button from '../../Inputs/Button';
import { isEmpty } from '../../../utils';
import { setLoading } from '../../../redux/actions/categoryActions';
import instance from 'src/http';

function CreatePoll({ history }) {
  const [pollName, setPollName] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);

  const handleSubmit = async () => {
    setloading(true);
    const payload = {
      name: pollName,
    };

    try {
      const res = await instance.post('/polls', payload);
      setLoading(false);
      history.push(`/dashboard/polls/poll/${res.data.id}`);
    } catch (err) {
      setErrors(err.response);
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Create a new poll</h1>
      <TextField
        name="pollName"
        value={pollName}
        label="Set an internal name for your poll"
        onChange={(e) => setPollName(e.target.value)}
        error={isEmpty(errors) ? null : errors.data.name}
        maxLength={50}
      />
      <Button text="Create" onClick={handleSubmit} loading={loading} />
    </div>
  );
}

export default withRouter(CreatePoll);
