import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../Inputs/TextField';
import LanguageSwitcher from '../../Layout/LanguageSwitcher';
import Wysiwyg from '../../Inputs/Wysiwyg/Wysiwyg';
import FileUpload from '../../Inputs/FileUpload';
import Button from '../../Inputs/Button';
import { isEmpty } from '../../../utils';
import css from './Slide.module.scss';
import instance from 'src/http';

function Slide({ slide, deselectSlide, addSlide, slides, deleteSlide }) {
  const initialState = {
    name: '',
    link: '',
    content: '',
    image: null,
    content_fr: '',
    image_fr: null,
    id: null,
  };
  const [slideData, setSlideData] = useState(initialState);
  const [tab, setTab] = useState('en');
  const [error, setError] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isEmpty(slide)) {
      setSlideData({
        ...slideData,
        name: slide.name,
        link: slide.link,
        content: slide.content,
        content_fr: slide.content_fr,
        image: slide.image,
        image_fr: slide.image_fr,
        id: slide.id,
      });
    }
  }, [slide]);

  // Change handlers
  const changeTabs = (tab) => {
    setTab(tab);
  };
  const onChange = (e) => {
    setSlideData({ ...slideData, [e.target.name]: e.target.value });
  };
  const handleWysiwyg = (content) => {
    if (tab === 'en') {
      setSlideData({
        ...slideData,
        content: content,
      });
    } else
      setSlideData({
        ...slideData,
        content_fr: content,
      });
  };
  const handleImage = (image) => {
    setSlideData({ ...slideData, image: image });
  };
  const handleImageFr = (image) => {
    setSlideData({ ...slideData, image_fr: image });
  };

  const { name, link, image, image_fr, content, content_fr } = slideData;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);

    const imagePayload = new FormData();
    if (typeof image !== 'string') imagePayload.append('image', image);
    if (typeof image_fr !== 'string') imagePayload.append('image_fr', image_fr);

    const payload = {
      name,
      content: content,
      content_fr: content_fr,
      image: image,
      image_fr: image_fr,
      link,
    };

    try {
      const method = isEmpty(slide) ? 'post' : 'put';
      const path = isEmpty(slide) ? '/slides' : `/slides/${slide.id}`;
      const res = await instance[method](path, payload);
      if (isEmpty(slide)) {
        addSlide([...slides, res.data]);
      } else {
        const newArr = slides.slice();
        for (let i = 0; i < slides.length; i++) {
          if (newArr[i].id === res.data.id) newArr.splice(i, 1, res.data);
        }
        addSlide(newArr);
      }
      setSlideData(initialState);
      setLoading(false);
      window.location.reload();
    } catch (err) {
      setLoading(false);
      setError(err.response.data);
    }
  };

  const handleFileUpload = (imgType, file) => {
    setSlideData({ ...slideData, [imgType]: file });
  };

  const header = !isEmpty(slide) ? `Editing slide ${slide.id}` : 'New slide';

  const handleDelete = () => {
    deleteSlide(slideData.id);
    setSlideData(initialState);
  };

  if (loading) return <p>Loading...</p>;

  let contentErr = error.content;
  if (error.content_fr) contentErr = error.content_fr;

  return (
    <div className={css.container}>
      <div className={css.header}>
        <h2>{header}</h2>
        <Button
          onClick={() => {
            deselectSlide({});
            setSlideData(initialState);
          }}
          text="New Slide"
        />
      </div>
      <LanguageSwitcher language={tab} changeLanguage={changeTabs} />
      <TextField
        name="name"
        type="text"
        label="Slide name"
        value={name}
        onChange={onChange}
        error={error.name ? error.name : undefined}
      />
      <TextField
        name="link"
        type="text"
        label="Slide link"
        value={link}
        onChange={onChange}
        error={error.link ? error.link : undefined}
      />
      <Wysiwyg
        name="content"
        label="Slide content"
        val={tab === 'en' ? content : content_fr}
        onChange={handleWysiwyg}
        error={contentErr ? contentErr : undefined}
      />
      <div className="input-cntr">
        <FileUpload
          label="Slide image"
          imgType="image"
          getImageUrl={handleFileUpload}
          imgPreview={slideData.image}
          aspectRatio={296 / 223}
        />
        {error.image ? (
          <small className="invalid-feedback">
            <i className="fas fa-times" />
            {error.image}
          </small>
        ) : null}
      </div>
      <br />
      <div className="input-cntr">
        <FileUpload
          label="Slide image (French)"
          imgType="image_fr"
          getImageUrl={handleFileUpload}
          imgPreview={slideData.image_fr}
          aspectRatio={296 / 223}
        />
        {error.image_fr ? (
          <small className="invalid-feedback">
            <i className="fas fa-times" />
            {error.image_fr}
          </small>
        ) : null}
      </div>
      <br />
      <br />
      <div className={css.btn_container}>
        <Button text="Save slide" onClick={handleSubmit} />
        <Button
          text="Delete slide"
          onClick={handleDelete}
          styles={['btn', 'btn_red']}
        />
      </div>
    </div>
  );
}

Slide.propTypes = {
  slide: PropTypes.object,
  slides: PropTypes.array.isRequired,
  addSlide: PropTypes.func.isRequired,
  deselectSlide: PropTypes.func.isRequired,
  deleteSlide: PropTypes.func.isRequired,
};

export default Slide;
