import {
  Alert,
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ReorderButtons from '../components/ReorderButtons';

const options = [
  {
    id: 'mostPopular',
    name: 'Most Popular',
  },
  {
    id: 'recentlyAdded',
    name: 'Recently Added',
  },
  {
    id: 'manual',
    name: 'Manual',
  },
];

const layoutOptions = [
  {
    id: 'smallThumbnails',
    name: 'Small Thumbnails',
  },
  {
    id: 'largeThumbnails',
    name: 'Large Thumbnails',
  },
];

const limitOptions = Array.from({ length: 20 }, (e, i) => i + 1);

const BusinessCarouselModule = ({
  limit,
  variety,
  layout,
  businesses,
  showError,
  handleChange,
  handleLimitChange,
  handleVarietyChange,
  handleBusinessChange,
  handleColorChange,
  handleAddDiscount,
  handleRemoveItem,
  handleMoveDownItem,
  handleMoveUpItem,
}) => {
  const [businessText, setBusinessText] = useState('');
  const businessState = useSelector((state) => state.business);

  const getBusinessName = (business) => {
    const foundBusiness = businessState.businesses.rows.find(
      (op) => op.id === business
    );

    if (!foundBusiness) {
      return '';
    }
    return foundBusiness.name;
  };

  return (
    <>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="variety-select">Variant</InputLabel>
        <Select
          autoFocus
          labelId="variety-select"
          label="Type"
          value={variety}
          onChange={handleVarietyChange}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="limit-select">Limit</InputLabel>
        <Select
          labelId="limit-select"
          name="limit"
          label="Type"
          value={limit}
          onChange={handleLimitChange}
        >
          {limitOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="layout-select">Layout</InputLabel>
        <Select
          labelId="layout-select"
          name="layout"
          label="Type"
          value={layout}
          onChange={handleChange}
        >
          {layoutOptions
            .filter((op) => variety === 'manual' ?? op.id !== 'twoColumns')
            .map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {variety === 'manual' && (
        <>
          {businessState.loading && <CircularProgress size={20} />}
          {!!businessState.businesses.rows.length && (
            <Autocomplete
              disabled={limit === businesses.length}
              value={null}
              onChange={(event, newValue) => {
                handleBusinessChange(newValue, businesses.length, 'businesses');
                setBusinessText('');
              }}
              getOptionLabel={(item) => item.name}
              inputValue={businessText}
              onInputChange={(event, newInputValue) => {
                setBusinessText(newInputValue);
              }}
              id="combo-box-business"
              options={businessState.businesses.rows.filter(
                (option) => !businesses.find((op) => op == option.id)
              )}
              sx={{ width: '100%', mb: 2 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={`${businesses.length} Business(es) Selected`}
                />
              )}
            />
          )}

          {!!businesses.length &&
            !!businessState.businesses.rows.length &&
            businesses.map((business, index) => (
              <Stack
                key={index}
                direction="row"
                sx={{ padding: 1 }}
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <div style={{ width: 'calc(100% - 225px)' }}>
                  {getBusinessName(business)}
                </div>
                <Stack direction="row" alignItems="center" sx={{ width: 150 }}>
                  <CloseIcon
                    onClick={() => {
                      handleRemoveItem(index);
                    }}
                  />
                  <ReorderButtons
                    index={index}
                    items={businesses}
                    handleMoveDownItem={handleMoveDownItem}
                    handleMoveUpItem={handleMoveUpItem}
                  />
                </Stack>
              </Stack>
            ))}
        </>
      )}
      {showError && (
        <Alert sx={{ marginTop: 2 }} severity="error">
          Please select {limit} businesses
        </Alert>
      )}
    </>
  );
};

export default BusinessCarouselModule;
