import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../Inputs/TextField';
import Select from '../../Inputs/Select';
import Button from '../../Inputs/Button';

export class DiscountSearch extends Component {
  handleChange = (e) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  handleSelectOrderType = (orderType) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, orderType });
  };

  handleSelectOrderField = (orderField) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, orderField });
  };

  handleSelectBusiness = (business) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, business });
  };

  handleSelectCategory = (category) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, category });
  };

  render() {
    const { name, category, orderType, orderField, categories, business } =
      this.props.search;
    const { handleSearch, businesses } = this.props;

    const orderTypeOptions = [
      { label: 'Ascending', value: 'ASC' },
      { label: 'Descending', value: 'DESC' },
    ];

    const orderFieldOptions = [
      { label: 'Name', value: 'name' },
      { label: 'Business', value: 'businessName' },
      { label: 'Start date', value: 'startDate' },
      { label: 'End date', value: 'endDate' },
    ];

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center',
    };

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    return (
      <form
        className="flex"
        onSubmit={(e) =>
          handleSearch(
            e,
            name,
            category ? category.value : null,
            `${orderField.value},${orderType.value}`,
            business ? business.value : null
          )
        }
      >
        <TextField
          name="name"
          value={name}
          placeholder="Search"
          className="input-sm"
          onChange={this.handleChange}
          maxLength={30}
        />
        <Select
          value={business}
          name="Business"
          placeholder="Business"
          options={businesses.map((el) => ({ label: el.name, value: el.id }))}
          onChange={this.handleSelectBusiness}
          className="input-lg"
        />
        <Select
          value={category}
          name="category"
          options={this.props.categories}
          className="input-sm"
          placeholder="Category"
          onChange={this.handleSelectCategory}
          formatGroupLabel={formatGroupLabel}
        />
        <Select
          value={orderField}
          name="orderField"
          options={orderFieldOptions}
          className="input-sm"
          onChange={this.handleSelectOrderField}
          clearable={false}
          placeholder="Sort by"
        />
        <Select
          value={orderType}
          name="orderType"
          options={orderTypeOptions}
          className="input-sm"
          onChange={this.handleSelectOrderType}
          clearable={false}
        />
        <Button text="Search" type="submit" />
      </form>
    );
  }
}

DiscountSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
};

export default DiscountSearch;
