import React, { useEffect, useState } from 'react';
import Header from '../../Layout/Header';
import PollList from './PollList';
import Pagination from '../../Layout/Pagination';
import instance from 'src/http';

function PollDashboard(props) {
  const [page, setPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [polls, setPolls] = useState([]);
  const [totalResults, setTotalResults] = useState(null);

  const getPolls = async (page) => {
    const params = { params: { page: page } };
    try {
      const res = await instance.get('/polls', params);
      setPolls(res.data.results);
      setTotalResults(res.data.totalResults);
    } catch (err) {
      setErrors(err.response);
    }
  };

  useEffect(() => {
    getPolls(1);
  }, []);

  const onPageChange = (page) => {
    getPolls(page);
    setPage(page++);
  };

  return (
    <div>
      <Header
        title="Polls"
        btnText="New Poll"
        btnLink
        url="/dashboard/polls/createpoll"
      />

      <PollList polls={polls} />

      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={page}
          onPageChange={onPageChange}
        />
      ) : null}
    </div>
  );
}

PollDashboard.propTypes = {};

export default PollDashboard;
